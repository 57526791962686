.badge-status {
  border: 1px solid #333741;
  border-radius: 0.5rem;
  font-size: 12px;
  color: #cecfd2;
  background-color: #0c111d;
  padding: 2px 6px;
}

.badge-status .badge-status-dot {
  width: 8px;
  height: 8px;
  border-radius: 9999px;
  display: inline-block;
  margin-bottom: .5px;
  margin-right: 6px;
  background-color: rgb(23, 178, 106);
}

.badge-status.cancelled {
  border-color: #ee46bc;
}

.badge-status.cancelled .badge-status-dot {
  background-color: #ee46bc;
}

.badge-status.expired {
  border-color: #333741;
}

.badge-status.expired .badge-status-dot {
  background-color: #333741;
}

.badge-status.pending {
  border-color: #333741;
}

.badge-status.pending .badge-status-dot {
  background-color: #eedd46;
}
