.ticket-payment-qr-detail .items span {
  background-color: #fff;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  line-height: 25px;
  color: #000;
}
