.occasionButton {
  font-size: 0.8rem;
  font-weight: 700;
  padding: 0.5rem;
  margin: 1px;
  border-radius: 10px;
  border: none;
  background: #000;
  border: 1px solid var(--color-deep-gray);
  color: var(--color-medium-gray);
  background-color: #000;
  outline: none !important;
}

.occasionButton.selected {
  border: 2px solid var(--color-primary);
  margin: 0;
  color: #fff
}