.restaurantCard {
  width: 100%;
  height: 112px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #1a1a1a !important;
}

.campaignCardUnactive {
  background-color: var(--color-dark-gray);
  width: 100%;
  height: 112px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0.5;
}

.containerImg {
  width: 45px;
  height: 45px;
}

.containerImg img {
  width: 100%;
  object-fit: cover;
}

.exclamationButton {
  background: none;
  border: none;
  padding: 0;
  margin-left: 8px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

.exclamationButton:focus,
.exclamationButton:active {
  outline: none;
  border: none;
  box-shadow: none;
}

.containerModal {
  border-radius: 15px;
}

.titleModal {
  font-family: 'Inter', sans-serif;
  font-weight: 800;
  font-size: 22px;
  line-height: 26.63px;
  letter-spacing: -0.5px;
  color: #d9d9d9;
  margin-bottom: 20px;
}
.subtitleModal {
  font-size: 15px;
  line-height: 18.15px;
  letter-spacing: -0.5px;
  color: #d9d9d9;
  margin-bottom: 15px;
}
.paragraphModal {
  color: #7e7e7e;
  font-size: 15px;
  line-height: 18.15px;
  letter-spacing: -0.5px;
}

.paragraphModalBottom {
  color: #7e7e7e;
  font-size: 15px;
  line-height: 18.15px;
  letter-spacing: -0.5px;
}

.pageContent {
  height: 100%;
}
.pageContainer {
  padding-bottom: calc(100px + env(safe-area-inset-top));
  min-height: 70vh;
  margin: 8px;
}

.upcomingText {
  border: 1px solid  rgb(232, 232, 232);
  padding: 2px 5px; 
  border-radius: 2px;
  margin-left: 10px;
  width: 85px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 10px;
  color: rgb(232, 232, 232);
  
}
