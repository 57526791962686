.delete-account-screen-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-top: 20%;
}

.delete-account-screen-container h3 {
  text-align: center;
  font-size: 1.1rem;
  width: 90%;
}

.delete-account-screen-circle {
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: var(--color-dark-gray);
  width: 194px;
  height: 194px;
}
