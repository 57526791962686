.buttonWellet {
  border: none;
  border-radius: 10px;
}

.buttonWellet:focus {
  outline: none;
}

.buttonWellet .text {
  font-weight: 700;
}

.buttonWellet .lineHeight {
  line-height: 24px;
}

.buttonWellet .iconWelletCheck {
  font-weight: 700;
  font-size: 1.75rem;
}

.buttonWellet.disabled {
  color: rgba(0, 0, 0, 0.5);
  opacity: 1 !important;
}

.buttonWellet.disabled::after {
  content: none;
}

.buttonWellet.fillBack .btnProgress {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: auto;
}

.buttonWellet .btnContent {
  z-index: 10;
  position: relative;
  display: block;
}

.buttonWellet .btnProcessing {
  z-index: 10;
  position: relative;
  display: none;
}

.buttonWellet .btnProgress {
  width: 0%;
  z-index: 5;
  background: var(--color-secondary);
  opacity: 0;
  transition: all 0.3s ease;
  border-radius: 10px;
}

.buttonWellet.btnActive {
  pointer-events: none;
  position: relative;
  z-index: 1;
}

.buttonWellet.btnActive .btnProgress {
  opacity: 1;
  animation: progress-anim 30s ease 0s;
  color: #fff !important;
}

.buttonWellet.btnActive .btnContent,
.buttonWellet.btnComplete .btnContent,
.buttonWellet .btnCheck {
  display: none;
}

.buttonWellet.btnActive .btnProcessing {
  display: block;
}

.buttonWellet.btnComplete {
  width: 45px !important;
  height: 45px !important;
  line-height: 45px;
  border-radius: 50%;
  background-color: #018648 !important;
  padding: 0;
  display: flex;
  justify-content: center;
  animation: pulse 0.5s cubic-bezier(0.2, 0.8, 0.2, 1) forwards;
  border: none !important;
  pointer-events: none;
  margin: 0 auto;
}

.buttonWellet.btnComplete .btnCheck {
  display: block;
  color: #fff;
}

.buttonWellet .btnLabell {
  display: flex;
  align-items: center;
}

.btnBorderError {
  border: 1px solid var(--color-red) !important;
}

@keyframes progress-anim {
  0% {
    width: 0%;
  }
  50% {
    width: 50%;
  }
  100% {
    width: 100%;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.border {
  border: 1px solid #fff;
}
