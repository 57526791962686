.fade-appear.inner-fade,
.fade-appear .inner-fade,
.fade-enter.inner-fade,
.fade-enter .inner-fade {
  opacity: 0;
}

.fade-appear-active.inner-fade,
.fade-appear-active .inner-fade,
.fade-enter-active.inner-fade,
.fade-enter-active .inner-fade {
  transition: opacity 0.5s linear;
  opacity: 1;
}

.fade-exit.inner-fade .fade-exit .inner-fade {
  transition: opacity 0.3s linear;
  opacity: 1;
}

.fade-exit-active.inner-fade,
.fade-exit-active .inner-fade {
  opacity: 0;
}

.fade-appear .slide-up,
.fade-enter .slide-up {
  opacity: 0;
  -webkit-transform: translate3d(0, 20%, 0);
  transform: translate3d(0, 20%, 0);
}

.fade-appear-active .slide-up,
.fade-enter-active .slide-up {
  transition: transform 0.5s ease-in-out, opacity 0.3s linear;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  opacity: 1;
}

.fade-exit .slide-up {
  transition: opacity 0.3s linear;
  opacity: 1;
}

.fade-exit-active .slide-up {
  opacity: 0;
}
