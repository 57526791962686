.textareaContainer {
  border: none;
}

.iconContainer {
  margin-left: 5px;
}

.card {
  border-radius: 10px;
}

.textarea {
  height: 98px;
  width: 100%;
  resize: none;
  background-color: var(--color-dark-gray);
  border: 1px solid var(--color-deep-gray);
  border-radius: 10px;
  color: #fff;
  outline: none;
}

.textareaContainer {
  width: 100%;
}

.textarea::placeholder {
  font-size: 14px;
}

.open {
  margin-bottom: 5rem;
}
