.container {
  border-radius: 10px;
  padding: 1.2rem;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  position: relative;
  width: 100%;
  background-color: #1a1a1a;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.points {
  position: absolute;
  right: 25px;
  top: 18px;
}

.point {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-top: 3px;
  background-color: var(--color-light-gray);
}
