@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;700&display=swap');

.fullscreenContainer {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.title {
  font-size: 24px;
  padding-top:  36px;
  margin-bottom: 10px;
  color: #000;
  font-weight: 700;
  font-family: 'Space Grotesk', sans-serif;
}

.dates {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  margin-top: 3px;
  font-family: 'Space Grotesk', sans-serif;
}

.backgroundImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.textContainer {
  position: absolute;
  width: 100%;
  height: 30%;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 2;
  max-width: 80%;
}

.description {
  margin-top: 10px;
  font-size: 12px;
  color: #242422;
  font-weight: 400;
  font-family: 'Space Grotesk', sans-serif;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Space Grotesk', sans-serif;
}
