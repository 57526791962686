.summaryBox {
  align-items: center;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  border: 1px solid #7e7e7e;
  height: 72px;
  text-align: center;
  background-color: #000;
}

.subtitle {
  font-size: 0.75rem;
}
