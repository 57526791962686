.carousel-container {
  text-align: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.carousel-header {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 10px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.back-button,
.skip-button {
  background: none;
  border: none;
  color: var(--color-primary);
  font-size: 16px;
  cursor: pointer;
}

.carousel-slide {
  position: relative;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.carousel-slide img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.carousel-dots {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: var(--color-lighter-gray);
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
  cursor: pointer;
}

.dot.active {
  background-color: var(--color-primary);
}

.carousel-title {
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  font-weight: 800;
  line-height: 29.05px;
  letter-spacing: -0.5px;
  text-align: center;
  margin: 10px 0;
  margin-left: 30px;
  margin-right: 30px;
  color: var(--color-lighter-gray);
}

.carousel-subtitle {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  letter-spacing: -0.5px;
  color: var(--color-deep-gray);
  text-align: center;
  margin-left: 60px;
  margin-right: 60px;
}

.perfect-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: var(--color-primary);
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
