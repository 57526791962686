.gear {
  position: absolute;
  top: 13px;
  right: 2%;
  top: 50%;
  transform: translateY(-50%);
}

.container {
  border-radius: 7px;
  background-color: #191919;
}

.border {
  border-bottom: 0.22px solid #cacaca;
}
