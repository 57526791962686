.playButton {
  position: absolute;
  top: 45%;
  right: 42%;
  transform: translateY(-50%);
  font-size: 22px;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  text-align: center;
  line-height: 22px;
}

.selectedMedia {
  width: 109px;
  height: 94px;
  margin-top: 1rem;
  border-radius: 10px;
}

.selectedVideo {
  width: 109px !important;
  margin-top: 1rem;
  height: 94px;
}

.selectedVideo video {
  height: 94px;
  object-fit: cover;
}

.custombuttonUno {
  width: 97px;
  height: 27px;
  background-color: black;
  color: #1D9BF0;
  border: 1px solid #1D9BF0;
  display: inline-block; 
  border-radius: 5px 0 0 5px;
  white-space: nowrap;
  outline: none;
  box-shadow: none;
}
.custombuttonDos {
  width: 97px;
  height: 27px;
  background-color: 
  #212121;
  color: 
  #7E7E7E;
  border: 1px solid 
  #7E7E7E;
  display: inline-block; 
  border-radius: 0 5px 5px 0;
  white-space: nowrap;
  outline: none;
  box-shadow: none;
}

.custombuttonDark{
  width: 97px;
  height: 27px;
  background-color: 
  #212121;
  color: 
  #7E7E7E;
  border: 1px solid 
  #7E7E7E;
  display: inline-block; 
  border-radius: 5px 0 0 5px;
  white-space: nowrap;
  outline: none;
  box-shadow: none;
}

.custombuttonDarkDark2{
  width: 97px;
  height: 27px;
  background-color: black;
  color: #1D9BF0;
  border: 1px solid #1D9BF0;
  display: inline-block; 
  border-radius: 0 5px 5px 0;
  white-space: nowrap;
  outline: none;
  box-shadow: none;
}



.custombuttonUno:focus,
.custombuttonDark:focus,
.custombuttonDarkDark2:focus {
  outline: none;
  box-shadow: none;
} 

