/* Slider container */
.slider {
    display: flex;
    overflow-x: hidden;
}

/* Slide container */
.slide {
    transition: all 0.6s ease-in-out;
    /* adjust the spacing between slides */
}
.slide.active {
    /* border: 1px solid yellow; */
    transition: all 0.3s ease-in-out;

}

/* Navigation buttons (optional) */
.navigation-buttons button {
    /* add styles for the navigation buttons */
}
.btn-slider-prev svg{
    transform: rotate(180deg) !important;
}
::-webkit-scrollbar {
    display: none;
}
.slide-transition {
    transition: transform 0.6s ease-in-out;
}

.slide .moneyCents {
    font-size: 0.5rem;
}