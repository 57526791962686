.restaurantCard {
  width: 100%;
  height: 112px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #1A1A1A !important;
}

.containerImg {
  width: 70px;
  height: 70px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    45deg,
    #fdc738,
    #fecb39,
    #fcaf45,
    #f7a536,
    #f59b38,
    #f18938,
    #ed6e41,
    #eb5d4c,
    #eb5850,
    #ea5055,
    #e94563,
    #e94168,
    #e83a85,
    #e8398d,
    #d24592,
    #a64b96
  );
}

.imgRestaurant {
  border-radius: 50%;
  width: 92%;
  height: 92%;
  border: 4px solid var(--color-dark-gray);
}
