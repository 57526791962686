.unread {
  color: #fff;
}

.read {
  color: #9fa3b2;
}

.container {
  padding: 0.8rem 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.container:last-child {
  border-bottom: none;
}
