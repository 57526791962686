.checkboxContainer {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  display: block;
  margin: 0;
}

.checkboxContainer input {
  display: none;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkboxContainer .checkmark {
  height: 2rem;
  width: 2rem;
  border: 3px solid #fff;
  border-radius: 5px;
}

.checkboxContainer .checkmark:after {
  content: '';
  position: absolute;
  display: none;
  left: 13px;
  top: 3px;
  width: 8px;
  height: 20px;
  border: solid var(--color-primary);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkboxContainer input:checked ~ .checkmark {
  background-color: transparent;
}

.checkboxContainer input:checked ~ .checkmark:after {
  display: block;
}

/* .checkboxContainer .checkmark:after {

} */
