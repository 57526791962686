.paxContainer {
  width: 47px;
  height: 47px;
  color: #d9d9d9;
  font-weight: 500;
  border-radius: 50%;
  border: 1px solid #7e7e7e;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: 400;
}

.paxSelected {
  border: 2px solid var(--color-primary) !important;
  font-weight: bolder;
}

.customPax {
  background-color: #000;
  border: none;
  color: #fff;
  width: 20%;
  font-size: 30px;
}

.paxHandle {
  width: 40px;
  height: 40px;
  color: #d9d9d9;
  font-weight: 500;
  border-radius: 50%;
  border: 2px solid var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
}
