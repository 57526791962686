.transport-selector {
  background-color: var(--color-medium-gray);
  color: #fff;
  border-radius: 7px;
}

.transport-selector .transport-selector__control {
  background-color: transparent;
  border: none;
}

.transport-selector .transport-selector__indicator-separator {
  display: none;
}
.transport-selector__indicator .css-tj5bde-Svg {
  width: 22px;
  height: 22px;
}

.transport-selector .transport-selector__menu-list {
  background-color: var(--color-medium-gray);
}

.transport-selector .transport-selector__option {
  padding: 0.25rem 0.5rem;
  color: #fff;
  background-color: var(--color-medium-gray);
}

.transport-selector .css-tj5bde-Svg {
  margin-right: 0;
}

.transport-selector .transport-selector__placeholder,
.transport-selector,
.modtransportal-selector .transport-selector__dropdown-indicator {
  font-weight: lighter;
  color: #fff;
}

.transport-selector .time-selected .transport-selector__placeholder {
  font-weight: bold;
  color: #fff;
}
.transport-selector .transport-selector__single-value {
  font-weight: bold;
  color: #fff;
}

.card-tip {
  height: 59px;
  min-width: 86px;
  background-color: var(--color-medium-gray);
  border: none;
  border-radius: 8px;
  padding: 0px 7px;
  line-height: 1.1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  transition: all 0.3s ease-in-out;
  color: #fff;
}

.language-selector-container {
  margin-top: 1rem;
  display: flex;
}

.language-selector-container > div:first-child {
  flex: 1;
}

.language-selector-btn {
  background-color: var(--color-medium-gray);
  color: #fff;
  border: none;
  border-radius: 6px;
  align-items: center;
  padding: 0.15rem 0.55rem;
  text-transform: uppercase;
}

.language-selector-btn:active {
  transition: none;
  transform: translateY(1px);
}

.language-selector-flag {
  width: 32px;
  height: 32px;
  background-image: url(https://cdn1.wellet.fun/images/flags/sprite-flags-32x32.png);
  transform: scale(0.6);
  display: inline-flex;
}

.language-selector-flag-en,
.language-selector-flag-US {
  background-position: -224px -448px;
}
.language-selector-flag-AR {
  background-position: -384px -32px;
}
.language-selector-flag-MX {
  background-position: -64px -320px;
}
.language-selector-flag-pt {
  background-position: -480px -64px;
}
.language-selector-flag-ru {
  background-position: -64px -384px;
}
.language-selector-flag-cn {
  background-position: -448px -96px;
}
.language-selector-flag-de {
  background-position: -192px -128px;
}
.language-selector-flag-fr {
  background-position: -256px -160px;
}
.language-selector-flag-hi {
  background-position: -64px -224px;
}
.language-selector-flag-ES {
  background-position: 0px -160px;
}

.language-selector-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--color-dark-gray);
  border-radius: 5px;
  padding: 3rem 2rem 2rem;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  width: 90%;
  color: #fff;
  z-index: 5;
}

.language-selector-modal .close-page {
  width: 32px;
  height: 32px;
  background-color: transparent;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 0.5rem;
  cursor: pointer;
}

.language-selector-modal-opened {
  opacity: 1;
  pointer-events: auto;
}

.modal-selector {
  background-color: var(--color-dark-gray);
  color: #fff;
}

.modal-selector .modal-selector__control {
  border-radius: 0;
  background-color: transparent;
  border: 1px solid var(--color-medium-gray);
  background: transparent;
}

.modal-selector .modal-selector__control:hover {
  border-color: var(--color-medium-gray);
}

.modal-selector .modal-selector__placeholder,
.modal-selector .modal-selector__single-value,
.modal-selector .modal-selector__dropdown-indicator {
  color: var(--color-light-gray);
  text-transform: uppercase;
}

.modal-selector .modal-selector__indicator-separator {
  display: none;
}

.modal-selector .modal-selector__menu {
  background-color: var(--color-dark-gray);
  border-radius: 0;
  margin-top: -1px;
  border: 1px solid var(--color-medium-gray);
}

.modal-selector .modal-selector__menu-list {
  max-height: none;
  padding-top: 0;
  padding-bottom: 0;
}

.modal-selector .modal-selector__option {
  text-transform: uppercase;
  padding: 0.25rem 0.5rem;
}

.modal-selector .option-inner {
  display: flex;
  align-items: center;
  height: 32px;
}

.modal-selector .modal-selector__option--is-focused {
  background-color: var(--color-medium-gray);
}

.customer-language-selector-flag {
  width: 32px;
  height: 32px;
  background-image: url(https://cdn1.wellet.fun/images/flags/sprite-flags-32x32.png);
}
