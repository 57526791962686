.video-item {
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  text-align: center;
  /* background-color: rgb(45, 48, 53);
  height: 256px; */
}

.video-item video {
  width: 100%;
  /* object-fit: contain;
  object-position: center;
  transform: scale(1, 1); */
  display: block;
}

.qr-image img {
  width: 100%;
}

.copy-link {
  line-height: 18px;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 0.9rem;
  display: inline-block;
  vertical-align: middle;
}
