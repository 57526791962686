.menuNav {
    bottom: 0;
    left: 0;
    z-index: 10;
  }
  
  .customerNav {
    padding-bottom: env(safe-area-inset-bottom);
    background-color: rgba(9, 9, 9, 0.9);
    border-top: 1px solid #282828;
  }
  

  
  .active {
    border-color: var(--color-primary);
  }
  
  .active p {
    color: var(--color-primary);
  }
  
  .link:hover {
    outline: none;
    text-decoration: none;
  }
  
  .alertQuantity {
    border-radius: 15px;
    min-width: 15px;
    height: 15px;
    background-color: var(--color-primary);
    position: absolute;
    left: 50%;
    top: -5px;
    color: #fff;
    font-size: 0.7rem;
    padding: 0 5px;
    line-height: 15px;
  }
  
  .notificationIcon {
    position: relative;
  }
  
  .outerCircle {
    background-color: #1D9BF0; 
    border-radius: 50%; 
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px; 
    height: 70px;
    border: 6px solid #282828;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -10px;
    z-index: 15; 
  }
  
  
 
  .link {
    text-align: center;
    border-top: 3px solid transparent;
    padding-top: 0.8rem;
    padding-bottom: 2.5rem;
  }