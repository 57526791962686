.container {
  border-radius: 20px;
}

.title {
  color: #d9d9d9;
}

.imgContainer {
  min-height: 293px;
  min-width: 293px;
}
