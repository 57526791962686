.input-single-character {
  display: inline-block;
  position: relative;
}

.field .input-single-character input {
  max-width: 50px;
  height: 50px;
  line-height: 54px;
  font-size: 24px;
  border-radius: 6px;
  text-align: center;
  outline: 0;
  display: inline;
  margin: 0 0.2rem !important;
  background-color: #000;
  border: 1px solid var(--color-primary) !important;
  color: #fff;
}

.input-single-character .error-label::after {
  content: none;
}

.input-single-character {
  display: flex;
  justify-content: center;
}

.input-character .field.error {
  border: none;
}

.error-label-code {
  background-color: #f80005;
  margin-top: 16%;
  font-size: 12px;
  padding: 2px 5px 2px 8px;
  position: absolute;
  width: 60%;
  color: #fff;
  text-align: left;
}
