.lineaGris {
    background-color: #525252; 
    height: 1px; 
    width: 100%; 
    margin-top: 10px; 
  }
  
  .rectanglesContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 12px;
    font-weight: 700;
    color: #7E7E7E;
  }
  
  .rectangle {
    background-color: #030303;
    border-radius: 10px;
    flex: 1; 
    padding: 5px;
    text-align: center;
    margin-right: 10px; 
    border: none; 
    color: #7E7E7E; 
    cursor: pointer;
    outline: none; 
  }
  
  .rectangle:last-child {
    margin-right: 0; 
  }
  
  .textSecondary {
    font-size: 32px;
    margin-top: 10px;
    color: #D9D9D9;
  }
  
  .activeButton {
    color: #ffffff; 
  }
  
  .rectangle:focus {
    outline: none; 
  }
  
