.mobile-bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  will-change: transform;
  transform: translateZ(0);
  display: flex;
  height: 50px;
  box-shadow: 0 -2px 5px -2px var(--color-dark-gray);
  background-color: #000;
}

.mobile-bottom-nav__item {
  flex-grow: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mobile-bottom-nav__item--active {
  color: red;
}

.mobile-bottom-nav__item-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
