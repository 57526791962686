.container {
  width: 100%;
  border: 1px solid var(--color-deep-gray);
  background-color: var(--color-dark-gray);
  color: #fff;
  border-right: none;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  letter-spacing: -1px;
  position: relative;
}

.container .moreInfo {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  background-color: var(--color-primary);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1.1rem;
  transition: opacity 0.3s ease-in-out;
  visibility: hidden;
}

.container:hover .moreInfo {
  opacity: 0.9;
  visibility: visible;
}

.soldOut .container {
  background-color: #393939;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border-right: 1px solid var(--color-deep-gray);
}

.productName {
  padding-right: 22px;
  display: inline-block;
  max-width: 100%;
  position: relative;
}

.productSubTitle {
  color: #d9d9d9;
}

.productPrice {
  font-size: 1.1rem;
  font-weight: 700;
  margin-top: .5rem;
}

.soldOut .productName {
  color: var(--color-lighter-gray);
}

.productInfoIcon {
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
}

.add {
  background-color: var(--color-primary);
  width: 45px;
  border-radius: 0 10px 10px 0;
  color: #000;
  font-size: 48px;
  text-align: center;
  font-weight: 300;
  border: none;
  outline: none !important;
  padding: 0;
}

.subtract {
  width: 45px;
  color: #000;
  font-size: 48px;
  text-align: center;
  font-weight: 300;
  background-color: var(--color-deep-gray);
  border: none;
  outline: none !important;
  padding: 0;
}

.quantityContainer {
  display: flex;
  height: 100%;
  justify-content: flex-end;
}

.disabled .container,
.disabled .quantityContainer,
.disabled .quantityCheckbox {
  cursor: inherit;
  pointer-events: none;
  opacity: .7;
}

.quantityCheckbox {
  height: 100%;
  border: 1px solid var(--color-deep-gray);
  background-color: var(--color-dark-gray);
  border-left: none;
  padding-right: 1rem;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  align-items: center;
}

.soldOut .quantityContainer {
  padding: 0 1rem;
}

.quantity {
  font-size: 30px;
  font-weight: 300;
  text-align: center;
}

.soldOutLabel {
  background-color: var(--color-alert);
  font-size: 0.8rem;
  border-radius: 20px;
  padding: 0.1rem 0.5rem;
  text-transform: uppercase;
  font-weight: 600;
}

.productImage {
  width: 68px;
  height: 60px;
  object-fit: cover;
  border-radius: 10px;
}

.productCode {
  position: absolute;
  border-radius: 50%;
  width: 29px;
  height: 29px;
  border: 2px solid var(--color-dark-gray);
  left: 50%;
  transform: translateX(-50%);
  bottom: -15%;
  font-size: 0.9rem;
  text-align: center;
  line-height: 25px;
  font-weight: 600;
  overflow: hidden;
}
