.cardContainer {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .redRectangle {
    position: absolute;
    top: 0;
    right: 0;
    width: 20%;
    height: 100%;
    background-color: #202020;
    z-index: 10;
    border-radius: 0 10px 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra horizontalmente */
    justify-content: center; /* Centra verticalmente */
    color: white; /* Asegúrate de que el texto sea visible */
  }
  
  .lineaGris {
    background-color: #525252; 
    height: 1px; 
    width: 100%; 
    margin-top: 10px; 
  }
  
  .textName {
    font-size: 20px;
    margin-top: 10px;
    color: #D9D9D9;
  }
  
  .textSecondary {
    font-size: 15px;
    margin-top: 10px;
    color: #1D9BF0;
    font-weight: 700;
  }
  
  .activeButton {
    color: #ffffff; 
  }
  
  .rectangle:focus {
    outline: none; 
  }
  
  .myCardDigital {
    font-size: 11px;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: 400;
    color: white; /* Asegúrate de que el texto sea visible */
    text-align: center; /* Opcional: Alinea el texto al centro */

  }
  
  
  .myCard{
    font-size: 13px;
    
    
      }