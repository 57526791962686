.campaignCard {
  background-color: var(--color-dark-gray);
  display: flex;
  padding: 1rem;
  border-radius: 10px;
}

.containerImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.logoImg {
  width: 100%;
  object-fit: contain;
}

.calendarButton {
  background-color: var(--color-primary);
  color: #fff;
  width: 100%;
  border: none;
  border-radius: 5px;
  color: #000;
  font-size: 14px !important;
}

.secondButton {
  border: 1px solid #3d3d3d;
  border-radius: 5px;
  font-size: 14px !important;
  font-weight: 500;
}
