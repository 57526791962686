.containerImg {
  height: 40px;
  border-radius: 50%;
  width: 40px;
}

.restaurantImg {
  border-radius: 50%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.feedVideo {
  width: 100%;
  height: auto;
  max-height: 55vh;
  border-radius: 10px;
  object-fit: cover;
}

.fullScreenContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: calc(81px + env(safe-area-inset-bottom));
}

.fullScreenVideo {
  position: fixed;
  top: 0;
  transition: all 0.3s ease;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: black;
}

.fullScreenImg {
  height: 100%;
  width: 100%;
  margin: auto 0;
  object-fit: contain;
}

.expanding {
  transform: scale(1.05);
}

.feedImg {
  width: 100%;
  height: auto;
  max-height: 55vh;
  border-radius: 10px;
}

.feedVideo,
.feedImg {
  transition: all 0.3s ease;
}

.shareText {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 40px;
  background: linear-gradient(to top, #0000005e, transparent);
  text-align: center;
  color: #fff;
  z-index: 10001;
}

.containerSteps {
  background-color: #000;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  bottom: 0;
  left: 0;
  padding-bottom: calc(25px + env(safe-area-inset-bottom));
  position: fixed;
  right: 0;
  transition: all 0.5s ease-in-out;
  z-index: 22222;
}

.containerSteps.show {
  animation: slideInUp 0.5s forwards;
}

.containerSteps.hide {
  animation: slideOutDown 0.5s forwards;
}

.stepCard {
  background-color: #d9d9d91a;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  text-align: center;
  justify-content: center;
  margin-bottom: 25px;
}

.iconButton {
  background-color: #d9d9d9;
  margin: 0 0.5rem;
  padding: 0.5rem 1.2rem;
  border-radius: 20px;
}

.cardShareCampaigns {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #d9d9d9;
  margin-top: 1rem;
  text-align: center;
  border-radius: 20px;
  font-weight: bold;
}

.stepsButtons {
  margin-top: 1rem;
}

.stepsButtons button {
  border-radius: 50%;
  margin: 0.5rem;
  font-weight: bold;
  border: none;
  background-color: #9d9c9c;
  width: 30px;
  outline: none;
  height: 30px;
  padding: 0;
}

.imgStep {
  width: 100%;
  height: 250px;
}

.nextStepButton {
  border: none;
  background-color: #000;
  color: #fff;
  border-radius: 30px;
  padding: 0.75rem;
  margin: 1rem;
  outline: none !important;
  width: 80%;
  font-weight: 700;
}

@keyframes slideInUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOutDown {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
}
