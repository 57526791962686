.buttonCustom {
  display: flex;
  position: relative;
  align-items: flex-end;
  justify-content: space-between;
  border-radius: 20px;
  padding: 10px;
  border: none;
  outline: none;
  box-shadow: none;
}

.buttonCustom:focus,
.buttonCustom:active {
  outline: none;
  box-shadow: none;
}

.leftIcon {
  position: absolute;
  top: 7px;
  left: 5px;
  margin: 5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #4cb7ff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rightIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  margin: 5px;
}

.text {
  align-self: flex-end;
  margin-left: 5px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 17px;
  line-height: 1.1;
  letter-spacing: -0.17px;
  margin-top: 10px;
}
