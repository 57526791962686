.associates-login-footer {
  color: var(--color-light-gray);
}

@media screen and (min-height: 600px) {
  .associates-login-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.associates-login-footer-icon {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  position: relative;
  top: -2px;
}

.associates-login-footer a {
  color: var(--color-light-gray);
  transition: all 0.3s ease-in-out;
}

.associate-user {
  background-color: var(--color-dark-gray);
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
}

.associate-user-transparent {
  background-color: transparent;
}

.associate-user-photo {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  margin-right: 1rem;
}

.associate-user-photo-profile {
  position: relative;
  z-index: 10;
  width: 5.5rem;
  height: 5.5rem;
  border-radius: 50%;
  margin: 0 auto 1rem auto;
  background-color: #000000;
  border: 0.2rem solid #ffffff;
  align-items: center;
  justify-content: center;
}

.associate-profile-card {
  display: flex;
  flex-direction: column;
  background-color: var(--color-dark-gray);
  border-radius: 0.25rem;
  padding: 4rem 1rem 1rem 1rem;
  margin-top: -4rem;
}

.associate-profile-card .associate-user-name {
  text-align: center;
  line-height: 18.33px;
}

.associate-profile-card .associate-user-title {
  text-align: center;
  line-height: 17px;
  margin: 0.5rem 0 1rem 0;
}

.associate-qr {
  background-color: #ffffff;
  border-radius: 17px;
  padding: 1rem;
  margin: 1rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.associate-user-title {
  line-height: 15px;
  font-weight: 300;
}

.associate-user-rating {
  display: none;
  margin-left: 0.25rem;
  font-weight: 500;
}

.associate-user-rating svg {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-top: -2px;
  margin-right: 0.25rem;
}

.associate-user-name {
  margin-top: 3px;
  line-height: 18.33px;
}

/* balance */
.associate-balance {
  border-radius: 10px;
  font-size: 0.9rem;
}

.moneyCents {
  font-size: 0.625rem;
  position: absolute;
  right: 0px;
  top: 0px;
}

.cents {
  margin-left: 2px;
  position: absolute;
  top: 3px;
}

.centsAffiliate {
  vertical-align: super;
  margin-bottom: 1rem;
  position: absolute;
  top: -10px;
}

.centsAffiliateDetail {
  vertical-align: super;
  margin-left: 2px;
}

.associate-balance .balance {
  font-size: 1.8rem;
  font-weight: 500;
}

.balance .moneyCents {
  font-size: 0.75rem;
  right: -1px;
  top: 2px;
}

.associate-balance .profits {
  font-size: 26px;
  font-weight: 300;
}

.associate-balance .btn {
  max-width: none;
  width: 100%;
  margin: auto;
  display: block;
  color: #000;
  border-radius: 6px;
  border: none;
  position: relative;
  transition: all 0.5s ease;
  z-index: 10 !important;
  height: 40px;
  line-height: 1;
  font-weight: 600;
}

.associate-balance .primary {
  background-color: var(--color-primary);
}

.associate-balance .secondary {
  background-color: transparent;
  border: 1px solid var(--color-light-gray);
  color: white;
}

.associate-balance .disable {
  background-color: var(--color-dark-gray);
  border: 1px solid var(--color-light-gray);
  color: var(--color-medium-gray);
}

.icon-container {
  position: absolute;
  top: 50%;
  right: 65%;
  transform: translateY(-27%);
}

/* alert */
.associate-alert .col-alert {
  padding: 0;
}

.associate-alert .text {
  font-weight: 600;
}

.associate-alert .icon {
  height: 25px;
  width: 25px;
}

/* activity */
.associate-activity {
  border-radius: 0.25rem;
  padding: 1.5rem 1rem;
}

/* VER */
.associate-activity .item-not-charge {
  margin: 5px auto;
}

.associate-activity .item-type {
  background-color: var(--color-dark-gray);
  border: 1px solid var(--color-light-gray);
  border-radius: 100%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.associate-activity .item-type .icon {
  width: 25px;
  height: 25px;
}

.associate-activity .item-type .icon-img {
  width: 35px;
  height: 35px;
}

.associate-activity .mini-item-type {
  background-color: var(--color-dark-gray);
  border: 1px solid var(--color-light-gray);
  border-radius: 100%;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: absolute;
  bottom: -8px;
  left: 40px;
}

.money {
  display: inline-block;
  padding-right: 15px;
  position: relative;
  line-height: 1;
}

.associate-activity .mini-item-type .icon {
  width: 11px;
  height: 11px;
  line-height: 11px;
}

.associate-activity .mini-item-type .icon svg {
  margin-top: -2px;
}

.associate-activity .cross-icon {
  width: 15px;
  height: 15px;
}

.associate-activity hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid var(--color-medium-gray);
}

.card-purchase {
  background-color: var(--color-dark-gray);
  border: 1px solid rgba(93, 93, 93, 0.33);
}

/* payment */
.associate-payment-form {
  border-radius: 7px;
  padding: 1rem;
}

.associate-payment-form .form-group label {
  display: flex;
  margin-bottom: 1rem;
}

.associate-payment-form .form-group label span {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 0.75rem;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid white;
  transition: all 300ms ease-in-out;
}

.associate-payment-form .form-group input + span:before {
  content: '';
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  margin-left: 4px;
  margin-top: 4px;
  background-color: #fff;
  border-radius: 50%;
  transition: all 300ms ease-in-out;
  opacity: 0;
}

.associate-payment-form .form-group input:checked + span:before {
  opacity: 1;
}

.associate-payment-title {
  font-weight: 600;
}

.associate-link-payment-title {
  color: #bdbec0;
}

/* Multiple payment */
.ticket-payment-multiple-total-container {
  background-color: var(--color-primary);
  border-radius: 0.5rem;
  margin-top: 2rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 2rem;
}

.ticket-payment-multiple-total-container .title {
  padding-top: 1rem;
  padding-bottom: 0;
  margin-bottom: 0;
}

.ticket-payment-multiple-bar {
  position: relative;
  height: 15px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 0.25rem;
  display: flex;
}

.ticket-payment-multiple-bar-progress {
  position: relative;
  margin-top: 3px;
  margin-bottom: 3px;
  background-color: var(--color-green);
  border-right: 2px solid #ffffff;
  transition: all 1000ms ease-in-out;
}

.ticket-payment-multiple-bar-progress span {
  position: absolute;
  right: 0;
  bottom: -25px;
  width: 100px;
  margin-right: -50px;
  text-align: center;
  color: #ffffff;
}

.ticket-payment-multiple-bar-start,
.ticket-payment-multiple-bar-end {
  position: absolute;
  bottom: -22px;
  width: 100px;
  text-align: center;
  color: #ffffff;
}

.ticket-payment-multiple-bar-start {
  left: 0;
  margin-left: -45px;
}

.ticket-payment-multiple-bar-end {
  right: 0;
  margin-right: -40px;
}

.ticket-payment-multiple-items-container {
  margin-top: 1rem;
}

.ticket-payment-multiple-items-title {
  padding-bottom: 0;
  margin-bottom: 0.25rem;
}

.ticket-payment-multiple-items__item {
  background-color: var(--color-dark-gray);
  display: flex;
  align-items: flex-start;
  border-radius: 0.25rem;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.ticket-payment-multiple-items__item > img {
  width: 40px;
  margin-right: 0.75rem;
}

.ticket-payment-multiple-items__item > div {
  width: 100%;
}

.ticket-payment-multiple-items__item-field-container {
  display: flex;
}

.ticket-payment-multiple-items__item-title {
  margin-bottom: 0.25rem;
  color: #ffffff;
  text-align: left;
}

.ticket-payment-multiple-items__item-field {
  display: block;
  position: relative;
  height: 60px;
  text-align: left;
  background-color: var(--color-dark-gray);
  border-radius: 0.25rem;
  padding: 0.5rem;
  color: #ffffff;
}

.ticket-payment-multiple-items__item-field small {
  display: block;
}

.ticket-payment-multiple-items__item-field span {
  display: block;
}

.ticket-payment-multiple-items__item-field img {
  position: absolute;
  width: 22px;
  height: 22px;
  bottom: 0.5rem;
  right: 0.75rem;
}

.ticket-payment-multiple-items__item-field:first-child {
  width: 95px;
}

.ticket-payment-multiple-items__item-field:last-child {
  margin-left: 0.5rem;
  flex: 1;
}

.ticket-payment-multiple-items__item-field.pending {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ticket-payment-multiple-items__item-field.pending span {
  font-weight: normal;
  margin-left: 0.5rem;
}

.ticket-payment-multiple-items__item-field.pending img {
  position: static;
}

.ticket-payment-multiple-items__item-toggle {
  margin-top: 0.5rem;
  display: block;
  text-align: right;
  text-decoration: underline;
  font-weight: 300;
  cursor: pointer;
}

.ticket-payment-multiple-items__item .field {
  margin-top: 0.5rem;
  margin-bottom: 0;
}

.ticket-payment-multiple-items__item .field .floating-placeholder {
  left: 15px;
}

.ticket-payment-multiple-items__item-operation-number {
  position: relative;
}

.ticket-payment-multiple-items__item-operation-number img {
  position: absolute;
  top: 1rem;
  right: 0.75rem;
  width: 22px;
  height: 22px;
}

.ticket-payment-multiple-items__item-operation-number button {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  top: 7px;
  right: 7px;
  border-radius: 6px;
  border: none;
  font-weight: 500;
  color: #fff;
  background-color: var(--btn-tertiary-color);
  outline: 0;
  height: 44px;
}

.ticket-payment-multiple-items__item-operation-number button:disabled,
.toggle-on .ticket-payment-multiple-items__item-operation-number button:disabled {
  background-color: var(--color-light-gray);
  color: rgba(255, 255, 255, 0.7);
}

/* Mercado Pago */
.mercado-pago-point-smart-reference {
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 1.5rem 1rem;
  color: #000;
}

.mercado-pago-point-smart-reference__image {
  width: 100%;
}

.mercado-pago-point-smart-reference__title {
  margin-top: 0.5rem;
  text-align: center;
}

.mercado-pago-point-smart-reference__comment {
  line-height: 1.2;
}

.mercado-pago-point-smart-reference__comment span {
  background-color: #000;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  line-height: 25px;
  color: #fff;
}

.mercado-pago-point-smart-reference .text-large,
#cashPaymentModal .text-large {
  line-height: 28px;
}

.mercado-pago-point-smart-reference .payment-currency {
  display: block;
  margin-top: -0.25rem;
}

.mercado-pago-point-smart-reference__countdown--cancelled {
  background-color: var(--color-red);
  color: #ffffff;
}

.mercado-pago-point-smart-reference .check {
  font-size: 2.5rem;
  color: var(--color-green);
  line-height: 1;
}

.mercado-pago-point-smart-reference .btn {
  border-radius: 3px;
  margin-left: auto;
  margin-right: auto;
}

.mercado-pago-point-smart-reference .btn-checkout {
  justify-content: center;
}

.mercado-pago-point-smart-reference .btn-cancel.btn-lg {
  width: 100%;
  border-radius: 0;
  height: 60px;
  border-radius: 3px;
}

.mercado-pago-point-smart-reference__cancel-container {
  margin-top: 1rem;
}

.mercado-pago-point-smart-reference__cancel-button {
  background-color: transparent;
  border: none;
  color: white;
  text-decoration: underline;
  padding: 0;
}

.mercado-pago-point-smart-reference__cancel-button span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--color-red);
  border: 1px solid var(--color-red);
  border-radius: 50%;
  margin-right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  text-decoration: none;
}

.mercado-pago-point-smart-reference__cancel-question div {
  color: white;
  margin-bottom: 0.25rem;
}

.mercado-pago-point-smart-reference__cancel-question .btn {
  display: inline-block;
  padding-top: 3px;
  padding-bottom: 3px;
}

.mercado-pago-point-smart-reference__cancel-question .btn.btn-confirm {
  margin-left: 0.5rem;
  background-color: var(--color-red) !important;
  color: white;
}

.mercado-pago-point-smart-reference__cancel-container .icon-wellet-alert-attention {
  display: inline-block;
  margin-right: 0.5rem;
}

/*associate balance deposit*/

.associate-balance-deposit {
  display: flex;
  flex-direction: column;
  background-color: var(--color-dark-gray);
  border-radius: 7px;
  padding: 1rem 1rem 1rem 1rem;
}

.wallet-img {
  width: 30%;
}

.wallet-img img {
  width: 100%;
  height: 100%;
}

.associate-balance-deposit .header-icon {
  margin-left: 20px;
}

.associate-balance-deposit .show-img {
  width: 60px;
  height: 60px;
  margin: 10px auto;
  border-radius: 50%;
}

.title {
  padding: 0;
  text-align: center;
  line-height: 18.33px;
}

.associate-balance-deposit .usd-amount {
  text-align: center;
  font-size: 1.8rem;
}

.associate-balance-deposit .mxn-amount {
  text-align: center;
  color: var(--color-light-gray);
  line-height: 5px;
}

.associate-balance-deposit .associate-qr {
  height: 288px;
  width: 288px;
}

.customer-language-selector.customer-language {
  z-index: 11;
}

.customer-language-selector .customer-language-selector__value-container {
  padding: 6px 0;
}

.customer-language-selector .customer-language-selector__control {
  border-radius: 3px;
  padding: 0px 15px;
  height: 60px;
  border: none;
}

.customer-language-selector .customer-language-selector__indicator-separator {
  display: none;
}

.customer-language-selector .customer-language-selector__indicator {
  color: #000;
}

.customer-language-selector .customer-language-selector__menu {
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.customer-language-selector .customer-language-selector__option {
  background-color: transparent;
  color: #000;
}

.customer-language-selector .customer-language-selector__placeholder {
  color: #000;
  margin-left: 25px;
}

.line hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid var(--color-medium-gray);
}

.icon-raster {
  padding-bottom: 10px;
}

.empty-activity-span {
  font-size: 0.9rem;
  color: var(--color-medium-gray);
}

.operationNumber .field i {
  margin-top: -9px;
}
