.summary {
}

.summary .channel-summary {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.8);
}

.summary .channel-summary:last-child {
  padding: 0;
  margin: 0;
  border: none;
}

.summary .channel-summary svg {
  vertical-align: text-top !important;
}
