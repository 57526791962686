.button-continue {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 4rem;
  font-size: 1.1rem;
  left: 0;
  bottom: 1rem;
}

.button-continue .btn {
  background-color: var(--color-primary);
  color: #000 !important;
}

.cashier-operation-image img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid var(--color-primary);
  margin: auto;
  object-fit: cover;
}

.button-continue-cashier {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 4rem;
  font-size: 1.1rem;
  left: 0;
  bottom: 3rem;
}

.activity-profile-image img {
  width: 56px;
  height: 56px;
  border-radius: 100%;
  object-fit: cover;
}
