.imageContainer img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 4px solid #353535;
  object-fit: cover;
}

.imageContainer {
  width: 80px;
  height: 80px;
}
