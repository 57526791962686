.custombuttonUno {
  height: 27px;
  background-color: black;
  color: #1d9bf0;
  border: 1px solid #1d9bf0;
  display: inline-block;
  border-radius: 5px 0 0 5px;
  white-space: nowrap;
  outline: none;
  box-shadow: none;
}
.custombuttonDos {
  height: 27px;
  background-color: #212121;
  color: #7e7e7e;
  border: 1px solid #7e7e7e;
  display: inline-block;
  border-radius: 0 5px 5px 0;
  white-space: nowrap;
  outline: none;
  box-shadow: none;
}
.custombuttonDark {
  height: 27px;
  background-color: #212121;
  color: #7e7e7e;
  border: 1px solid #7e7e7e;
  display: inline-block;
  border-radius: 5px 0 0 5px;
  white-space: nowrap;
  outline: none;
  box-shadow: none;
}
.custombuttonDarkDark2 {
  height: 27px;
  background-color: black;
  color: #1d9bf0;
  border: 1px solid #1d9bf0;
  display: inline-block;
  border-radius: 0 5px 5px 0;
  white-space: nowrap;
  outline: none;
  box-shadow: none;
}
.custombuttonUno:focus,
.custombuttonDark:focus,
.custombuttonDarkDark2:focus {
  outline: none;
  box-shadow: none;
}
