/* -------------- VARIABLES --------------- */
:root {
  /* -------------- COLORS --------------- */
  --color-primary: #1d9bf0;
  --color-secondary: #05141e;
  --color-dark-blue: #0017e8;
  --color-dark-purple: #313166;

  --color-lighter-gray: #d9d9d9;
  --color-light-gray: #0f0f0f;
  --color-medium-gray: #898989;
  --color-deep-gray: #7e7e7e;
  --color-dark-gray: #131313;

  --color-light-green: #00ff7e;
  --color-green-yellow: #8fff00;
  --color-green: #029c4f;
  --color-red: #ff0046;
  --color-orange: #ff8421;
  --color-warning: #ffb648;
  --color-light-blue: #00a3ff;
  --color-dark-blue: #1f242f;
  --color-darker-blue: #0c111d;
  --color-commission: #556379;
  --color-alert: #a60707;
  --gold: #ffc82c;
  --silver: #66c1da;
  --platino: #aeaeae;
  --diamond: #0a0a0a;

  --btn-primary-progress-color: #05141e;

  /* -------------- FONTS --------------- */
  --color-font-primary: #fff;
  --color-font-gray: #c0c0c0;

  /* -------------- BACKGROUND --------------- */
  --bg-primary: #f7f7f7;
  --bg-secondary: #e8e8e8;
  --bg-white: #fff;
  --bg-input: #f2f2f2;
}
/* -------------- END VARIABLES --------------- */

.bg-primary {
  background-color: var(--bg-primary) !important;
}

.bg-secondary {
  background-color: var(--bg-secondary) !important;
}

.bg-dark-gray {
  background-color: var(--color-dark-gray);
}

.bg-darker-gray {
  background-color: var(--color-dark-gray);
}

.bg-white {
  background-color: var(--color-dark-gray);
}

.text-secondary {
  color: var(--color-deep-gray) !important;
}

.text-primary {
  color: var(--color-primary);
}

.text-lighter-gray {
  color: var(--color-lighter-gray);
}

.divider {
  border-top: 1px dashed var(--color-medium-gray);
}

.card {
  border: none;
  color: #fff;
  background-color: var(--color-dark-gray) !important;
}

.card-header,
.card-footer {
  background-color: unset;
}

.btn-primary,
.btn-primary:hover {
  background-color: var(--color-primary);
  color: #000 !important;
  border-color: var(--color-primary);
}

.btn-primary:disabled,
.btn-primary.disabled {
  background-color: rgba(29, 155, 240, 0.5) !important;
  color: #000 !important;
  border-color: rgba(29, 155, 240, 0.5) !important;
  pointer-events: none;
}

.btn-cancel {
  background-color: rgba(255, 255, 255, 0.7) !important;
  color: #000 !important;
}

.btn-cancel:hover {
  background-color: var(--color-light-gray);
}

.btn-cancel.disabled,
.btn-cancel:disabled {
  color: var(--color-light-gray);
}

.btn-select {
  background-color: var(--color-medium-gray);
  color: #fff;
  border: 0.33px solid #808183;
}

/* .btn-select:hover,
.btn-select.selected {
  background-color: #503ec4 !important;
  color: #fff !important;
  font-weight: bold !important;
} */

@media (max-width: 640px) {
  .btn-select.selected {
    background-color: var(--color-primary);
    color: #000;
  }
}

.show-time-btn {
  background-color: var(--color-dark-gray);
  color: #fff;
}

.selected-performance {
  border: 2px solid var(--color-primary) !important;
  font-weight: bold !important;
  background-color: #0a0a0a;
}

.selected-performance:disabled {
  background-color: #141414 !important;
}

.show-time-btn:disabled {
  opacity: 1;
  background-color: rgba(19, 19, 19, 0.5);
  text-decoration: line-through !important;
  color: var(--color-deep-gray);
  border: 1px solid var(--color-dark-gray);
}

.btn-check,
.btn-check:hover {
  background-color: var(--color-medium-gray);
  color: #fff;
}

.btn-check.selected {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: #000;
}

@media (hover: hover) and (pointer: fine) {
  .btn-check:hover {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    color: #000;
  }
}

.btn-outline {
  background-color: transparent;
  border: 1px solid #fff;
}

.btn-outline-dark {
  background-color: transparent;
  border: 1px solid var(--color-light-gray);
  color: #fff;
}

.btn-outline-dark.disable {
  background-color: var(--color-dark-gray);
  border: 1px solid var(--color-light-gray);
  color: var(--color-medium-gray);
}

.btn-outline-black,
.btn-outline-black:hover {
  background-color: #000;
  border: 1px solid var(--color-medium-gray);
  color: #fff;
}

.btn-disabled {
  background-color: var(--color-light-gray) !important;
  border-color: var(--color-light-gray) !important;
  pointer-events: none;
  color: var(--color-medium-gray) !important;
}

.btn-error,
.btn-error:hover,
.btn-error:focus {
  background-color: var(--btn-error-color);
  border-color: var(--btn-error-color);
  color: #fff;
  pointer-events: none;
}
