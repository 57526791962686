.switch {
  width: 50px;
  height: 28px;
  background-color: var(--color-light-gray);
  border-radius: 15px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.1s ease;
}

.switch.active {
  background-color: var(--color-primary);
}

.circle {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 3px;
  transition: left 0.1s ease;
}

.switch.active .circle {
  left: calc(100% - 28px);
}
