.censuredContent {
  filter: blur(10px);
  z-index: 99;
}

.censuredText {
  position: absolute;
  color: #fff;
  z-index: 999;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
}
