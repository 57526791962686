.imageContainer {
  width: 182px;
  height: 256px;
  border-radius: 10px;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
}

.mediaContainer {
  position: absolute;
  top: 11%;
  left: 5%;
  margin-left: calc(var(--offset) * var(--index));
}

.imageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.imageContainerVideo {
  width: 100%;
  height: 100%;
}

.restaurantImg {
  border-radius: 50%;
  width: 92%;
  height: 92%;
}

.containerImg {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.seeMore {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 22px;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  text-align: center;
  line-height: 22px;
}

.seeMore {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 22px;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  text-align: center;
  line-height: 22px;
}
.checkIconContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.seeMoreAfter {
  background-color: #29a71a;
  border-color: #29a71a;
}

.circleButton {
  border-radius: 100%;
  width: 28px;
  height: 28px;
  top: 10px;
  right: 10px;
  font-size: 22px;
  text-align: center;
  line-height: 22px;
}

.playButton {
  position: absolute;
  top: 45%;
  right: 42%;
  transform: translateY(-50%);
  font-size: 22px;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  text-align: center;
  line-height: 22px;
}

.selectedMedia {
  width: 109px;
  height: 94px;
  margin-top: 1rem;
  border-radius: 10px;
}

.selectedVideo {
  width: 109px !important;
  margin-top: 1rem;
  height: 94px;
}

.selectedVideo video {
  height: 94px;
  object-fit: cover;
}

.descriptionLabel {
  position: absolute;
  left: 17px;
  top: 5px;
  background-color: #000;
  padding: 0.5rem;
}

.textarea {
  background-color: #000;
  width: 100%;
  border-radius: 10px;
  height: 150px;
  margin-top: 1.5rem;
  color: #fff;
  padding: 1rem;
  outline: none;
}

.calendarButton {
  border: 1px solid var(--color-deep-gray);
  padding: 0.75rem;
  border-radius: 10px;
  margin: 0.25rem 0;
}

.btn {
  margin-top: 0.75rem;
  color: #000 !important;
}
