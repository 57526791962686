.extra-price {
  text-align: right;
}

.special.group {
  display: -ms-flexbox;
}

.ticket-not-available {
  margin-top: 1rem;
  background-color: var(--color-dark-gray);
  border-radius: 0.625rem;
  padding: 2.5rem 0;
  display: flex;
}

.ticket-not-available-icon-container {
  padding-right: 0;
}

.ticket-not-available-icon {
  color: var(--color-red);
  font-size: 2.5rem;
}

.ticket-not-available-title {
  color: #fff;
}
.ticket-not-available-text-container {
  padding: 0 30px;
}
.ticket-not-available-description {
  color: rgba(255, 255, 255, 0.8);
  margin-top: 0.1rem;
  margin-bottom: 0;
}

.show-not-available {
  margin-top: 1rem;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 0.25rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
}

.show-not-available-icon-container {
  padding-right: 0;
}

.show-not-available-icon {
  color: #d94134;
  font-size: 2.5rem;
}

.show-not-available-title {
  font-size: 1.2rem;
  color: #000000;
  font-weight: bold;
}

.show-not-available-description {
  font-size: 0.9rem;
  color: #000000;
  margin-top: 0.1rem;
  margin-bottom: 0;
}

.edit-box {
  color: #000;
  background-color: #fff;
  min-height: 55px;
}

.edit-box.fixed {
  border-color: transparent !important;
  background-color: rgba(255, 255, 255, 0.8);
  height: 100%;
}
