.buttonContinue {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 4rem;
  font-size: 1.1rem;
  left: 0;
  bottom: 3rem;
}

.btn {
  background-color: var(--color-primary);
  color: #000 !important;
}


