.card {
  background: #fff;
  height: 5.5em;
  float: left;
  position: relative;
  padding: 1em;
}

.cardLeft {
  border-radius: 10px;
  width: 13em;
  display: flex;
  align-items: center;
}

.cardRight {
  width: 2em;
  border-left: 0.18em dashed #000;

  border-radius: 10px;
  position: relative;
}

.title {
  font-size: 12px;
  color: #000;
  font-weight: 700;
}
.dates {
  color: #959595;
  font-size: 10px;
  font-weight: 400;
  margin-top: 3px;
}
.description {
  color: #1d9bf0;
  font-size: 12px;
  font-weight: 700;
  margin-top: 2px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 5px;
}
.img {
  width:25%;
  margin-right: 15px;
}


.ticketContainer {
  display: flex;
  align-items: center;

}
