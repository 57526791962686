@font-face {
  font-family: 'wellet';
  src: url('https://cdn2.wellet.fun/fonts/wellet.eot?r99al3');
  src: url('https://cdn2.wellet.fun/fonts/wellet.eot?r99al3#iefix') format('embedded-opentype'),
    url('https://cdn2.wellet.fun/fonts/wellet.ttf?r99al3') format('truetype'),
    url('https://cdn2.wellet.fun/fonts/wellet.woff?r99al3') format('woff'),
    url('https://cdn2.wellet.fun/fonts/wellet.svg?r99al3#wellet') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-wellet-'],
[class*=' icon-wellet-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'wellet' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-wellet-calendar-2:before {
  content: '\e90e';
}
.icon-wellet-crown:before {
  content: '\e90d';
}
.icon-wellet-halloween:before {
  content: '\e90f';
}
.icon-wellet-check:before {
  content: '\e909';
}
.icon-wellet-chevron_right:before {
  content: '\e90b';
}
.icon-wellet-mail:before {
  content: '\e904';
}
.icon-wellet-ticket:before {
  content: '\e905';
}
.icon-wellet-alert-attention:before {
  content: '\e90c';
}
.icon-wellet-calendar:before {
  content: '\e900';
}
.icon-wellet-info:before {
  content: '\e906';
}
.icon-wellet-phone:before {
  content: '\e907';
}
.icon-wellet-card-reverse:before {
  content: '\e901';
}
.icon-wellet-card:before {
  content: '\e902';
}
.icon-wellet-close:before {
  content: '\e903';
}
.icon-wellet-secure:before {
  content: '\e908';
}
.icon-wellet-user:before {
  content: '\e90a';
}
