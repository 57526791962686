/*media queries */
@media (max-width: 1000px) {
  header .container {
    margin: 0;
    padding: 0;
  }

  header .container {
    max-width: inherit;
  }

  .navbar-toggler {
    z-index: 100;
  }
  /*NavBar Fixes Start*/

  .currency-language {
    display: inline-block;
  }

  .currency-language li {
    display: inline-block;
  }

  /*NavBar Fixes End */
}

@media (max-width: 767px) {
  .rccs {
    margin: auto;
    margin-bottom: 60px;
  }

  .navbar-nav .nav-link {
    margin-left: 5px !important;
  }

  .currency-language li button:active,
  .currency-language li button:hover {
    color: #fff;
  }

  .discount.group {
    text-align: center;
  }

  .available-tickets .inner-col {
    padding: 10px;
  }

  .promotion .row {
    padding: 0;
    margin: auto;
  }
}
