.associateUserImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}

.editIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
}
