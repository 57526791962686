.highlight .headerIconContainer {
  top: calc(15px + env(safe-area-inset-top));
  transform: unset;
}

.searchContainer {
  background-color: var(--bg-input);
  border-radius: 7px;
  color: var(--color-medium-gray);
  height: 45px;
  margin: auto;
  padding: 0.8rem;
}

.filterContainer {
  background-color: var(--bg-secondary);
  padding: 0.7rem;
  border-radius: 7px;
  height: 45px;
}

.cardContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.card {
  border-bottom: 0.33px solid #2a2a2b;
}

.imgWrapper {
  width: 79px;
  height: 70px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgWrapperNotSelected {
  border: 1px solid rgb(204 204 204 / 40%);
}

.imgWrapperSelected {
  border: 2px solid var(--color-deep-gray);
}

.img {
  width: 52px;
}

.showImgContainer {
  height: 175px;
}

.showImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
}

.logoImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.score {
  padding: 0.1rem 0.4rem 0.1rem 0.2rem;
  background-color: #604e35;
  border-radius: 7px;
  color: #fff;
}

.cardShowContainer .subtitle {
  font-size: 0.7rem;
}

.cardShowContainer .subtitle .money {
  width: 24px;
}
.imageContainer {
  position: relative;
}

.citySelector {
  border: 0.33px solid #000;

  color: #fff;
  height: 42px;
  border-radius: 7px;
}

.css-13cymwt-control {
  background-color: transparent;
}

.cityContainer {
  padding: 1rem;
  border: 2px solid var(--color-dark-gray);
  border-radius: 20px;
}
