.skeleton {
  padding: calc(15px + env(safe-area-inset-top)) 5px 5px;
}

.notificationsContainer {
  padding: 1rem;
  border-radius: 7px;
  background-color: var(--color-dark-gray);
}

.lineSkeleton {
  width: 90%;

  margin-bottom: 5px;
  border-top: 0.33px solid rgb(33, 33, 33);
}
