.withdraw .checkbox-container {
  position: relative;
  padding-left: 30px;
}

.withdraw .checkbox-container .checkmark,
.withdraw .checkbox-container input:checked ~ .checkmark {
  border-color: #fff;
}

.mp-email {
  max-width: 220px;
  font-weight: 400;
  line-height: 1.2rem;
}

.mp-logo {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.mp-logo-container {
  width: 42px;
}
