.error-box {
  color: #000;
  font-size: 0.8rem;
}

.error-box img {
  width: 25px;
}

.error-box .card {
  background-color: #f7dcda;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 0 !important;
}

.error-box .error-title {
  font-weight: 700;
  font-size: 1rem;
}

.error-box .error-text {
  margin-bottom: 0;
  line-height: 1.1rem;
}

.error-box .card-error {
  background-image: url('https://cdn1.wellet.fun/images/credit-card/card-error.png');
}
