.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-bottom: 100px;
  position: fixed;
  width: 100%;
  overflow: hidden;
}

.logo {
  padding-bottom: 40px;
  padding-top: 150px;
}

.instructionsTitle {
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  font-weight: 800;
  line-height: 29.05px;
  letter-spacing: -0.5px;
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
  color: #d9d9d9;
}

.instructionsSubTitle {
  text-align: center;
  margin-bottom: 80px;
  width: 100%;
  color: #7e7e7e;
}

.button {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

.header-fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.instructionsTitleCheck {
  font-family: 'Inter', sans-serif;
  font-size: 32px;
  font-weight: 800;
  line-height: 29.05px;
  letter-spacing: -0.5px;
  text-align: left;
  margin-bottom: 20px;
  margin-top: 20px;
  color: #d9d9d9;
  width: 80%;
}

.instructionsSubTitleCheck {
  font-size: 18px;
  text-align: left;
  margin-bottom: 80px;

  color: #7e7e7e;
}
