.campaignCard {
  background-color: var(--color-dark-gray);
  display: flex;
  margin-top: 0.5rem;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-radius: 20px;
  height: 112px;
}

.campaignType {
  color: var(--color-primary);
}

.containerImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.logoImg {
  width: 100%;
  object-fit: contain;
}
