.campaignCard {
  background-color: var(--color-dark-gray);
  display: flex;
  height: 112px;
  align-items: center;
  padding: 1rem;
  border-radius: 20px;
  overflow: hidden;
}

.campaignCardUnactive {
  background-color: var(--color-dark-gray);
  display: flex;
  height: 112px;
  align-items: center;
  padding: 1rem;
  border-radius: 20px;
  opacity: 0.5;
  overflow: hidden;

}

.containerImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.logoImg {
  width: 100%;
  object-fit: contain;
}

.upcomingText {
  border: 1px solid rgb(255, 255, 255);
  padding: 2px 5px; 
  border-radius: 2px;
  margin-left: 10px;
  padding-bottom: 2px;
  width: 85px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 10px;
  line-height: 12.1px;
  text-align: center;
  color: rgb(255, 255, 255);
}
