.card {
    background: #fff;
    height: 7.1em;
    float: left;
    position: relative;
    padding-left: 1em;
  }
  
  .cardLeft {
    border-radius: 10px;
    width: 18.5rem;
    display: flex;
    align-items: center;
    margin-left: 20px;
  }
  
  .cardRight {
    width: 2em;
    border-left: 0.18em dashed #000;
  
    border-radius: 10px;
    position: relative;
  }
  
  .title {
    font-size: 12px;
    color: #000;
    font-weight: 700;
  }
  .dates {
    color: #959595;
    font-size: 10px;
    font-weight: 400;
    margin-top: 3px;
  }
  .description {
    color: #1d9bf0;
    font-size: 12px;
    font-weight: 700;
    margin-top: 2px;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 5px;
  }
  .img {
    width:20%;
    margin-right: 15px;
  }
  
  
  .ticketContainer {
    display: flex;
    align-items: center;
    z-index: 9999;
    margin-top: 30px;
  }
  
  .all{
    width: 95px;
    border-radius: 4px;
    flex-direction: row;
    display: flex;
    color: #959595;
    margin-left: 20px;
    padding: 8px;
    margin-right: 20px;
  }

.circle {
  width: 17px;
  height: 16px;
  border-radius: 4px;
  background-color: #1D9BF0;
  color: #000;
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  margin-right: 8px;
}
