.activityFeed {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.card .activityFeed .feedItem:last-child {
  border-bottom: none;
}

.item1 {
  color: #e8e8e8;
}

.item2 {
  color: #dbdbdb;
}
