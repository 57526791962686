.line {
  height: 0.33px;
 border: 0.22px solid #212121;
}

.title {
  color: #fff;
}

.dot {
  height: 7px;
  width: 7px;
  background-color: var(--color-primary);
  border-radius: 50%;
}
