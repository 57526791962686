.menu-container {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.menu-button {
  width: 48px;
  height: 48px;
  position: absolute;
  top: env(safe-area-inset-top);
  right: 300px;
  border-radius: 0;
  cursor: pointer;
  background-color: #000;
  line-height: 48px;
  text-align: center;
}

.menu {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
}

.menu-open {
  pointer-events: auto;
}

.menu-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(177, 187, 189, 0.7);
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.menu .user-channel {
  color: var(--color-light-gray);
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.menu-content {
  color: var(--color-font-primary);
}

.menu-content nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu-content nav li {
  border-bottom: 1px solid var(--color-light-gray);
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  letter-spacing: 0.7px;
}

.menu-content nav li a,
.menu-content nav li button {
  display: block;
  color: #fff;
  padding: 0.5rem 0;
  line-height: 15px;
  border: none;
  background-color: transparent;
  letter-spacing: 0.5px;
}

.menu-content nav li a:hover,
.menu-content nav li button:hover {
  color: var(--color-font-primary);
}

.menu-content nav li a:active,
.menu-content nav li button:active {
  color: #ffffff;
  transition: none;
}

.menu-content nav li.disabled::after {
  content: none;
}

.menu-content nav li.disabled a,
.menu-content nav li.disabled svg {
  opacity: 0.7;
}

.menu-content nav li .menu-icon {
  width: 15px;
  height: 15px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.menu-img {
  width: 139px;
  opacity: 0.67;
}

.menu-content nav li .menu-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-font-primary);
}

.menu-content nav li:hover,
.menu-content nav li .menu-link {
  text-decoration: none;
  color: var(--color-font-primary);
}

.menu-content nav li .menu-icon svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.menu-version {
  color: var(--color-light-gray);
}

.menu-wellet-logo {
  width: 35px;
}
