.filter-select .filter-select__control {
  border: none;
  outline: 0;
  background-color: var(--color-dark-gray);
  box-shadow: none;
  color: #000;
  padding: 0.25rem 0.5rem;
}

.filter-select .filter-select__menu {
  background-color: var(--bg-primary);
  margin-top: 0;
  min-width: 150px;
}

.filter-select .filter-select__option {
  background-color: transparent;
  color: #000;
}

.filter-select .filter-select__single-value {
  color: #fff;
  padding: 0;
  font-weight: bold;
}

.filter-select .filter-select__value-container {
  padding: 0 3px;
  color: var(--color-primary);
}

.filter-select .filter-select__placeholder {
  color: #fff;
}

.filter-checkbox-container {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  line-height: 20px;
}

.filter-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.filter-checkmark {
  position: absolute;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #7f56d9;
  border-radius: 0.2rem;
}

.filter-checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.filter-checkbox-container input:checked ~ .filter-checkmark:after {
  display: block;
}

.filter-checkbox-container .filter-checkmark:after {
  left: 6px;
  top: 2px;
  width: 6px;
  height: 11px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.input-search {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 0.9rem;
}

.filter-buttons {
  height: 44px;
}

.calendar-button {
  padding: 0.5rem;
  background-color: var(--color-dark-gray);
  border-radius: 0.5rem;
  font-size: 0.9rem;
  height: 44px;
}

.input-from-to input {
  background-color: var(--color-dark-gray);
  border: 1px solid #212121;
  border-radius: 6px;
  padding: 0.75rem;
  margin-top: 0.5rem;
  color: #fff;
}

.input-from-to span {
  position: absolute;
  left: 12%;
  z-index: 10;
  bottom: 14px;
}

.all-reservations-buttons {
  padding-bottom: env(safe-area-inset-bottom);
}
