.content-app.reservation {
  padding-bottom: calc(140px + env(safe-area-inset-bottom));
}

.cart-button {
  width: 80px;
  height: 50px;
  border-radius: 10px;
  text-align: center;
  color: aqua;
}

.cart-button span {
  background-color: #6951ff;
  width: 30px;
  padding: 0.3rem;
  border-radius: 5px;
  color: #fff;
  margin-left: 0.25rem;
}

.checkout-button {
  width: 260px;
  height: 50px;
  color: white;
  border-radius: 10px;
}
.calendar {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  background-color: #fff;
  border-radius: 5px;
  border: 0.33px solid #bbb;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  width: 98%;
  color: #fff;
  z-index: 5000;
}

.calendar-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000; /* Asegúrate de que tenga un índice z alto para que aparezca por encima del contenido existente */
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.commission-button {
  background-color: var(--color-green);
  width: 102px;
  height: 39px;
  border-radius: 10px;
  color: black;
  line-height: 12px;
  font-weight: bold;
  padding: 0.25rem;
  margin-top: -0.4rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.checkout-button-mobile {
  padding-top: 0.5rem;
  padding-bottom: calc(0.5rem + env(safe-area-inset-bottom));
  bottom: 0;
  left: 0;
  background-color: #000;
}

.show-info-wrapper {
  position: fixed;
  justify-content: flex-end;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  animation: slide-in 0.3s ease-out;
  z-index: 9999;
}

.show-info-content {
  position: relative;
  background-color: #000;
  padding: 20px;
  width: 100%;
  height: 100%;
  animation: slide-in-content 0.3s ease-out;
  z-index: 10000;
  color: #fff;
  overflow: scroll;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

@keyframes slide-in {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slide-in-content {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

.product-list-container-right {
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
}

.product-list-container-left {
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
}

.commision-product {
  border-radius: 7px;
  padding: 0 0.5rem;
}

.show-min img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 0.3rem;
}

.itym-type {
  border-radius: 100%;
}

.item-type img {
  width: 35px;
  height: 35px;
}

.product-selector {
  height: 42px;
  border-radius: 7px;
  font-size: 16px;
}

.product-selector .product-selector__control {
  background-color: #000 !important;
  border: none !important;
  border-radius: 7px;
}

.product-selector .product-selector__option--is-focused {
  background-color: #fff !important;
}

.product-selector .product-selector__indicator-separator {
  display: none;
}

.product-selector .product-selector__dropdown-indicator {
  color: #503ec4;
}

.product-selector .product-selector__placeholder {
  color: #000;
  font-weight: bold;
}

.product-selector .product-selector__value-container :first-child {
  font-weight: bold;
  color: #fff;
  font-size: 1.2rem;
}

.css-1pm3hxk-ValueContainer {
  padding: 0 !important;
}

.css-tj5bde-Svg {
  width: 20px;
  height: 20px;
  margin-right: -7px;
}

.css-b62m3t-container {
  border: none !important;
}

.purchase-container {
  background-color: #fff;
  border-radius: 7px;
}

.show-name {
  font-size: 18px;
  color: #000;
}

.purchase-container .rating {
  width: 3.2rem;
  height: 1.7rem;
  background-color: #f7f7f7;
  border-radius: 7px;
  padding: 0.4rem;
  color: #000;
  font-weight: bold;
}

.purchase-container > div {
  color: #000;
  font-size: 13px;
}

.css-tr4s17-option {
  background-color: #fff !important;
  color: #000 !important;
}

.commisions-selector {
  height: 42px;
  border-radius: 7px;
  font-size: 16px;
}
.commisions-selector .commisions-selector__control {
  background-color: var(--color-dark-gray) !important;
  border: none !important;
  border-radius: 7px;
}

.commisions-selector .commisions-selector__option--is-focused {
  background-color: #fff !important;
}

.commisions-selector .commisions-selector__indicator-separator {
  display: none;
}

.commisions-selector .commisions-selector__dropdown-indicator {
  color: #503ec4;
}

.commisions-selector .commisions-selector__placeholder {
  color: #000;
  font-weight: bold;
}

.commisions-selector .commisions-selector__value-container :first-child {
  font-weight: bold;
  color: #fff;
}

.kvfysmfp {
  aspect-ratio: 18/17;
  border-radius: 15px;
  border: 5px solid #7e7e7e;
}

.kvfysmfp img {
  margin-left: -3px;
}

.view-more {
  font-size: 22px;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 18px;
  display: inline-block;
  margin-left: 0.5rem;
}

.credit-consume {
  border: 1px solid var(--color-primary);
  padding: 0 0.8rem;
  border-radius: 0.5rem;
  font-size: 0.8rem !important;
}

.total-credit-consume {
  color: #fff;
  width: 100%;
  padding: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-primary);
  border-radius: 10px;
}
