.mediaContainer {
  height: calc(100vh - 30px);
  object-fit: cover;
  width: 100%;
  display: flex;
}

.videoModal {
  width: 100%;
  height: auto;
}

.imgModal {
  height: auto;
  width: 100%;
  margin: auto 0;
}

.containerImg {
  height: 60px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shareButton {
  color: var(--color-primary);
  border: 3px solid var(--color-primary);
  background-color: #d9d9d9;
  width: 170px;
  border-radius: 30px;
  font-weight: bold;
  padding: 0.5rem;
}

.stepsButtons {
  margin-top: 1rem;
}

.stepsButtons button {
  border-radius: 50%;
  margin: 0.5rem;
  font-weight: bold;
  border: none;
  background-color: #9d9c9c;
  width: 30px;
  outline: none;
  height: 30px;
  padding: 0;
}

.nextStepButton {
  border: none;
  background-color: #000;
  color: #fff;
  border-radius: 30px;
  padding: 0.75rem;
  margin: 1rem;
  outline: none !important;
  width: 80%;
  font-weight: 700;
}

.stepCard {
  background-color: #d9d9d91a;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  text-align: center;
  justify-content: center;
  margin-bottom: 25px;
}

.iconButton {
  background-color: #d9d9d9;
  margin: 0 0.5rem;
  padding: 0.5rem 1.2rem;
  border-radius: 20px;
}

.cardShareCampaigns {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #d9d9d9;
  margin-top: 1rem;
  text-align: center;
  border-radius: 20px;
  font-weight: bold;
}

.imgStep {
  width: 100%;
  height: 250px;
}

.shareText {
  position: fixed;
  bottom: calc(51px + env(safe-area-inset-bottom));
  left: 0;
  right: 0;
  padding-bottom: 40px;
  background: linear-gradient(to top, black, transparent);
  text-align: center;
}

.containerSteps {
  transition: all 0.5s ease-in-out;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding-bottom: calc(75px + env(safe-area-inset-bottom));
  background-color: #000;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

@keyframes slideInUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOutDown {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
}

.containerSteps.show {
  animation: slideInUp 0.5s forwards;
}

.containerSteps.hide {
  animation: slideOutDown 0.5s forwards;
}
