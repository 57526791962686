.container {
  color: #fff;
  padding-bottom: calc(80px + env(safe-area-inset-bottom));
}

.imageContainer {
  width: 126px;
  height: 111px;
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
}

.seeMore {
  position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 22px;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    text-align: center;
    line-height: 22px;
}


.termsAndConditions p {
  padding-top: 1.25rem;
}

.termsAndConditions ul {
  color: var(--color-lighter-gray);
  font-size: .9rem;
}

.termsAndConditions ul li {
  margin: 1rem 0;
}