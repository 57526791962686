.container {
  background: rgba(0, 0, 0, 0.9);
}

.headerUser .headerContent {
  height: 55px;
  align-items: center;
}
.headerUser .profileContainer {
  background-color: #000;
  border-radius: 50%;
  height: 1.8rem;
  width: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerUser .profileContainer img {
  border-radius: 100%;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.headerUser .logo img {
  height: 40px;
}
