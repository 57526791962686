/*Pasar a wellet component*/
.fade:not(.show) {
  opacity: 0;
  z-index: -1;
}

/*Pasar a wellet component*/
.modal {
  display: inherit;
}

.btn-check,
.btn-check:hover {
  background-color: var(--color-medium-gray);
  color: #fff;
}

.btn-check.selected {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: #000;
}

@media (hover: hover) and (pointer: fine) {
  .btn-check:hover {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    color: #000;
  }
}

.card-show {
  padding-bottom: 1rem;
}

.card-show .swiper-slide {
  height: 375px;
  background-color: blue;
}

.filters {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filters .col-auto,
.filters .col {
  padding-right: 4px;
}

.filters .col {
  padding-left: 0;
}

.filters .col:last-child {
  padding-right: 15px;
}

.filters .btn {
  font-weight: normal;
  padding: 0 0.5rem;
  line-height: 25px;
  height: 25px;
  margin-right: 4px;
  border-radius: 7px;
  width: 100%;
}

.filters .btn-filter svg {
  margin-top: -4px;
}

.reload-btn {
  position: absolute;
  top: 20px;
  z-index: 1231;
  right: 30px;
}

.react-autosuggest__container {
  position: relative;
  height: 46px;
}

.react-autosuggest__container .search-icon {
  position: absolute;
  z-index: 6;
  top: 50%;
  transform: translate(12px, -50%);
}

.react-autosuggest__container .calendar {
  position: absolute;
  right: 12px;
  top: 53%;
  transform: translateY(-50%);
  line-height: 1;
  font-weight: 700;
  z-index: 5;
  padding-left: 0.5rem;
  background-color: #000;
}

.react-autosuggest__container .calendar svg {
  margin-top: -4px;
  margin-right: 0.25rem;
}

.react-autosuggest__input {
  width: 100%;
  border-radius: 20px;
  border: 1px solid var(--color-medium-gray);
  padding: 10px 15px 10px 40px;
  background-color: transparent;
  font-weight: 300;
  color: #fff;
  line-height: 24px;
  position: absolute;
  z-index: 5;
  background-color: #000;
}

.react-autosuggest__input::placeholder {
  color: #fff;
  opacity: 1;
}

.react-autosuggest__input:-ms-input-placeholder {
  color: #fff;
}

.react-autosuggest__input::-ms-input-placeholder {
  color: #fff;
}

.react-autosuggest__suggestions-container {
  background-color: var(--color-dark-gray);
  width: 100%;
  position: absolute;
  top: 23px;
  z-index: 2;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  padding: 0.5rem 0;
}

.react-autosuggest__container .suggest-pin {
  width: 42px;
  height: 42px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.8);
  display: block;
  border-radius: 5px;
  text-align: center;
  line-height: 42px;
}

.react-autosuggest__container img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.react-autosuggest__suggestions-container ul {
  padding: 25px 0 4px 0;
  margin: 0;
}

.react-autosuggest__suggestions-container ul li {
  list-style: none;
  padding: 5px 1rem;
}

.city-selector {
  width: 100%;
  background-color: transparent;
  color: #fff;
  border-radius: 7px;
  line-height: 1rem;
  padding: 1rem 0.5rem;
  font-weight: 700;
  border-color: var(--color-medium-gray);
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-chevron-down" viewBox="0 0 16 16"><path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>');
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: 50%;
}

/* ------ Modal Filter */
.modal-filters .checkbox-container {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  display: inline-block;
  line-height: 20px;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-top: -2px;
}

.modal-filters label {
  margin-bottom: 0;
}

.modal-filters .item-filter {
  line-height: 35px;
}

.modal-filters .checkbox-container .checkmark,
.modal-filters .checkbox-container input:checked ~ .checkmark {
  border-color: #fff;
}
