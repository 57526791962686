.scanPaymentContainer {
max-height: 70vh;
}

.qrScannerWrapper {
  position: relative;
  width: 100%;
  height: 60vh;
  overflow: hidden;
}

.qr {
  width: 100%;
  height: 100%;
  margin-top: 10%;
  object-fit: cover;
  transform: scale(1.9); 
  transform-origin: center;
}


.qrScannerOverlay {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  margin-left: 10%;
  margin-top: 20%;
  top: 0;
  left: 0;
  width: 80%;
  height: 70%;
  pointer-events: none;
}
.qrScannerOverlay::before {
  content: '';
  position: absolute;

  left: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(
    to top,
    rgba(126, 36, 51, 0.8),
    rgba(255, 253, 253, 0)
  );
  transform: translateY(-50%);
  pointer-events: none;
}

.qrScannerOverlay::after {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 4px; 
  background-color: #F53F5E; 
  
  pointer-events: none;
}
.cornerTopLeft {
  border-radius: 30px 0 0 0;
}
.cornerTopRight {
  border-radius: 0 30px 0 0;
}

.cornerBottomLeft {
  border-radius: 0 0 0 30px;
}

.cornerBottomRight {
  border-radius: 0 0 30px 0;
}

.cornerTopLeft,
.cornerTopRight,
.cornerBottomLeft,
.cornerBottomRight {
  position: absolute;
  width: 80px;
  height: 80px;
  border: 4px solid #f53f5e;
  pointer-events: none;
}

.cornerTopLeft {
  top: 0;
  left: 0;
  border-right: none;
  border-bottom: none;
}

.cornerTopRight {
  top: 0;
  right: 0;
  border-left: none;
  border-bottom: none;
}

.cornerBottomLeft {
  bottom: 0;
  left: 0;
  border-right: none;
  border-top: none;
}

.cornerBottomRight {
  bottom: 0;
  right: 0;
  border-left: none;
  border-top: none;
}

.scanInstructions {
  position: absolute;
 bottom: 0;
  left: 0;
  width: 100%;
  height: 44vh;
  background-color: #1a1a1a;
  border-radius: 32px 32px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #d9d9d9;
  font-size: 32px;
  font-weight: 800;
  z-index: 10; 
}

.customLine {
  width: 48px;
  height: 5px;
  background-color: #f2f2f2;
  border-radius: 100px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
}


