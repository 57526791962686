.wrapperAccordion {
  padding: 16px;
}

.accordion {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.item {
  margin-bottom: -10px;
}

.title {
  display: flex;
  justify-content: space-between;
  transition: transform 0.2s ease-in-out;
  align-items: center;
  color: var(--color-light-blue);
}

.content {
  color: rgba(0, 0, 0, 0.9);
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.content.show {
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--color-font-primary);
  padding-bottom: 1rem;
  text-align: justify;
}

.seeMore {
  border: none;
  color: var(--color-light-gray);
  background-color: transparent;
  margin-left: -5px;
  margin-bottom: 10px;
  margin-top: 7px;
}

.seeMore:focus {
  outline: none;
}

.listContainer {
  list-style-type: none;
  margin-bottom: 6px;
}

.listContainer div {
  display: flex;
  align-items: center;
  gap: 13px;
}

.errorIconContainer {
  text-decoration: line-through;
}

.line {
  height: 0.33px;
  background-color: rgba(255, 255, 255, 0.3);
}

.points {
  color: var(--color-primary);
  min-width: 60px;
  text-align: end;
}

.title {
  color: var(--color-primary);
}
