.trajeta {
  border-radius: 10px;
  overflow: hidden;
  flex-direction: column;
  margin-right: -35px;
}

.sliderContainer {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: 30px;
}

.iconContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inactive {
  width: 150px;
  height: 100px;
}

.delantera {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.logoMarca {
  margin-left: auto;
  padding-bottom: 25px;
  margin-top: 10px;


}
.logoMarcaAmex{
  margin-left: auto;
  padding-bottom: 20px;
  padding-top: 10px;

}
.chip {
  width: 35px;
  height: 35px;
  margin-bottom: 15px;
}

.logoYChip {
  display: flex;
  align-items: center;
}

.grupo {
  margin-bottom: 10px;
}

.label {
  font-size: 12px;
  margin-bottom: 5px;
}

.numero,
.nombre,
.expiracion {
  font-size: 14px;
  color: #fff;
}

.flexbox {
  display: flex;
  justify-content: space-between;
}

.mes,
.year {
  font-weight: bold;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.pagination-dot {
  width: 10px;
  height: 10px;
  background-color: grey;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.pagination-dot.active {
  background-color: white;
}

.addCard {
  width: 300px;
  height: 180px;
  background-color: #d1d1d1;
  border-radius: 10px;
margin-left: 40px;
  border: 2px dashed #747474;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 20px;
  color: #000;
  margin-right: 30px;
  text-align: center;
  flex-direction: column;
}

.addCardText {
  font-size: 14px;
  color: #000;
  margin-top: 7px;
}

.addCardTextSecondary {
  font-size: 12px;
}

.addCardIcon {
  width: 40px;
  height: 40px;
  background-color: #1d9aee;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
}
