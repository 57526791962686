.campaignCard {
  background-color: var(--color-dark-gray);
  display: flex;
  padding: 1rem;
  border-radius: 10px;
}

.campaignType {
  color: var(--color-primary);
}

.containerImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.logoImg {
  width: 100%;
  object-fit: contain;
}

.textarea {
  background-color: #000;
  width: 100%;
  border: 2px solid;
  border-radius: 10px;
  height: 150px;
  margin-top: 1.5rem;
  padding: 1rem;
}

.descriptionLabel {
  position: absolute;
  left: 17px;
  top: 5px;
  background-color: #000;
  padding: 0.5rem;
}

.hashTag {
  background-color: #000;
  padding: 1rem;
  border: 1px solid #7e7e7e;
  border-radius: 10px;
  margin-top: 1rem;
  height: 150px;
  position: relative;
}

.copyIcon {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.copyButton {
  border: 1px dashed #fff;
  text-align: center;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 10px;
}

.playButton {
  position: absolute;
  top: 54%;
  right: 30%;
  transform: translateY(-50%);
  font-size: 22px;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  text-align: center;
  line-height: 22px;
}

.selectedMedia {
  width: 64px;
  height: 55px;

  border-radius: 10px;
}

.selectedVideo {
  width: 64px !important;

  height: 55px;
}

.selectedVideo video {
  height: 94px;
  object-fit: cover;
}

.campaignLengthButton {
  position: relative;
  border: 1px solid #7e7e7e;
  padding: 1rem;
  color: #7e7e7e;
  border-radius: 10px;
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
}

.campaignLengthButton :first-child {
  position: absolute;
  top: -24px;
  left: 10px;
  background-color: #000;
  padding: 0.75rem;
  font-weight: 600;
}

.helpButton {
  border: 1px solid #3d3d3d;
  background-color: var(--color-primary);
  border-radius: 10px;
  width: 160px;
  color: #000;
  height: 72px;
  padding: 0.5rem;
  text-align: center;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.active {
  border: 1px solid #29a71a;
  position: absolute;
  margin-right: 10px;
  right: 0;
  color: #29a71a;
  border-radius: 5px;
  margin-left: 10px;
  padding: 5px;
  display: flex;
  align-items: center;
  height: 22px;
  width: 60px;
}
