.buttonWellet,
.btnSelected {
  border: none;
  border-radius: 10px;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonWellet:focus {
  outline: none;
}

.btnClickeable {
  background-color: #bbbbbb;
  color: #000;
  pointer-events: none; /* Desactivar eventos del puntero */
}

.disabledOpacity {
  background-color: #7e7e7e !important;
}

.border {
  border: 1px solid rgba(255, 255, 255, 0.4);
}

.text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.add {
  font-size: 40px;
  font-weight: 300;
}

.buttonWelletFixed {
  height: unset !important;
  display: block;
  border-radius: 0;
  padding: 1.4rem 0;
  padding-bottom: calc(1.4rem + (env(safe-area-inset-bottom) / 2));
}

.addCampaigns {
  font-size: 40px;
  font-weight: 300;
  margin-bottom: 5px;
  margin-left: 5px;
}
