.buttonsContainer {
  display: flex;
}

.containerImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.containerImg img {
  width: 100%;
  object-fit: contain;
}

.restaurantCard {
  width: 100%;
  height: 112px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #131313 !important;
}
