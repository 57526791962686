.no-content {
  background-color: #ccc;
  position: relative;
  overflow: hidden;
  border-radius: 0.25rem;
}

.no-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #ccc, #d9d9d9, #ccc);
  animation: skeleton-progress 2s ease-in-out infinite;
}

@keyframes skeleton-progress {
  0% {
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(100%, 0, 0);
  }
}

.loading-app {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  color: #000;
  background-color: #000;
  z-index: 10;
}
.loader-tickets {
  position: fixed;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 10;
}
.loaderDiv {
  margin-top: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000;
  width: 100%;
  z-index: 10;
}

.loaderDivProducts {
  /* margin-top: 20%; */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 10;
}

.loaderDivTransport {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #fff;
  z-index: 9999;
}

.loaderDivTransport div {
  border-width: 4px;
  margin: 0 auto;
}

.loaderDivTransport h2 {
  margin-top: 1rem;
  font-weight: normal;
  font-size: 1.2em;
  text-align: center;
}

.loaderDivProducts div,
.loader-tickets div {
  border-width: 4px;
  margin: 0 auto;
}

.loaderDivProducts h2,
.loader-tickets h2 {
  margin-top: 1rem;
  font-weight: normal;
  font-size: 1.2em;
  text-align: center;
}

.loaderDiv div {
  border-width: 4px;
  margin: 0 auto;
}

.loaderDiv h2 {
  margin-top: 1rem;
  font-weight: normal;
  font-size: 1.2em;
  text-align: center;
}

.form-spinner {
  display: block;
}

.form-spinner div {
  border-width: 4px;
  margin: auto;
}

.disabled-product {
  transition: all ease-in-out 0.3s;
  pointer-events: none;
  z-index: 9999;
}
.zIndex {
  z-index: 9999 !important;
}

.disabled-product::after {
  pointer-events: none;
  position: fixed;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.7);
}
.disabled-payment {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  content: '';
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9000;
  pointer-events: auto;
}

.disabled-checkout {
  transition: all ease-in-out 0.3s;
  opacity: 0.5;
  pointer-events: none;
}

[class^='circle__Wrapper'] {
  border-width: 2px !important;
}
