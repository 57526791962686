@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("https://cdn1.wellet.fun/fonts/roboto-300.eot");
  src: url("https://cdn1.wellet.fun/fonts/roboto-300.eot?#iefix") format("embedded-opentype"),
    url("https://cdn1.wellet.fun/fonts/roboto-300.woff2") format("woff2"),
    url("https://cdn1.wellet.fun/fonts/roboto-300.woff") format("woff"),
    url("https://cdn1.wellet.fun/fonts/roboto-300.ttf") format("truetype"),
    url("https://cdn1.wellet.fun/fonts/roboto-300.svg#Roboto") format("svg");
  font-display: block;
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("https://cdn1.wellet.fun/fonts/roboto-regular.eot");
  src: url("https://cdn1.wellet.fun/fonts/roboto-regular.eot?#iefix") format("embedded-opentype"),
    url("https://cdn1.wellet.fun/fonts/roboto-regular.woff2") format("woff2"),
    url("https://cdn1.wellet.fun/fonts/roboto-regular.woff") format("woff"),
    url("https://cdn1.wellet.fun/fonts/roboto-regular.ttf") format("truetype"),
    url("https://cdn1.wellet.fun/fonts/roboto-regular.svg#Roboto") format("svg");
  font-display: block;
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("https://cdn1.wellet.fun/fonts/roboto-500.eot");
  src: url("https://cdn1.wellet.fun/fonts/roboto-500.eot?#iefix") format("embedded-opentype"),
    url("https://cdn1.wellet.fun/fonts/roboto-500.woff2") format("woff2"),
    url("https://cdn1.wellet.fun/fonts/roboto-500.woff") format("woff"),
    url("https://cdn1.wellet.fun/fonts/roboto-500.ttf") format("truetype"),
    url("https://cdn1.wellet.fun/fonts/roboto-500.svg#Roboto") format("svg");
  font-display: block;
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("https://cdn1.wellet.fun/fonts/roboto-700.eot");
  src: url("https://cdn1.wellet.fun/fonts/roboto-700.eot?#iefix") format("embedded-opentype"),
    url("https://cdn1.wellet.fun/fonts/roboto-700.woff2") format("woff2"),
    url("https://cdn1.wellet.fun/fonts/roboto-700.woff") format("woff"),
    url("https://cdn1.wellet.fun/fonts/roboto-700.ttf") format("truetype"),
    url("https://cdn1.wellet.fun/fonts/roboto-700.svg#Roboto") format("svg");
  font-display: block;
}