.userQr {
  width: 292px;
  height: 292px;
  border-radius: 20px;
}

.qrContainer {
  margin-top: 1rem;
  position: relative;
  height: 292px;
  width: 292px;
}
