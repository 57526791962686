.accordionContainer {
  margin: 0 3px;
}

.completed {
  border-left: 2px solid var(--color-medium-gray);
}

.incompleted {
  border-left: 1px dashed var(--color-medium-gray);
}

.inprogress {
  border-left: 1px dashed var(--color-medium-gray);
}

.inprogressBorder {
  position: absolute;
  bottom: 0;
  width: 2px;
  left: -30%;
  z-index: 9;
  background-color: var(--color-medium-gray);
  animation: pulseBorder 2s infinite ease-in-out;
}

.inprogressDot {
  position: absolute;
  left: -7px;
  height: 15px;
  width: 15px;
  color: var(--color-medium-gray);
  background-color: var(--color-medium-gray);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  transition: all 2ms ease-in-out;
}

.dot,
.emptyDot {
  margin-left: -4.5px;
}

@keyframes expandHeight {
  0% {
    height: 0;
  }
  100% {
    height: 1;
  }
}

@keyframes fadeInOut {
  0% {
    bottom: 0;
  }
  100% {
    bottom: 1;
  }
}

.inprogressBorder {
  animation: expandHeight 1.5s ease;
}

.inprogressDot {
  animation: fadeInOut 1.5s ease;
}

.dot {
  border: 1px solid var(--color-medium-gray);
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: var(--color-medium-gray);
}

.emptyDot {
  border: 1px solid var(--color-medium-gray);
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: transparent;
}

.currentDot {
  height: 7px;
  width: 7px;
  background-color: var(--color-primary);
  border-radius: 50%;
}

.affliatedCard {
  background-color: var(--color-light-gray);
  color: var(--color-dark-gray);
  padding: 1rem;
  border-radius: 3px;
}

.status {
  border-left: 1px solid red;
}

.content {
  color: rgba(255, 255, 255, 0.9);
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.line {
  height: 0.33px;
  background-color: var(--color-light-gray);
}

.content.show {
  height: 100%;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}

.accordion {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.categoryTitle {
  display: flex;
  justify-content: space-between;
  transition: transform 0.2s ease-in-out;
  align-items: center;
  color: var(--color-light-blue);
}

.content {
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-deep-gray);
}

.seeMore {
  border: none;
  color: var(--color-light-gray);
  background-color: transparent;
  margin-left: -5px;
  margin-bottom: 10px;
  margin-top: 7px;
}

.seeMore:focus {
  outline: none;
}

.listContainer {
  list-style-type: none;
  margin-bottom: 6px;
}

.listContainer div {
  display: flex;
  align-items: center;
  gap: 13px;
}

.errorIconContainer {
  text-decoration: line-through;
}

.line {
  height: 0.33px;
  background-color: var(--color-light-gray);
}

.points {
  text-align: end;
  min-width: 94px;
}

.lineDotted,
.lineSolid {
  width: 1px;
  position: absolute;
  top: 0;
  bottom: 0;
  transform: translateX(-50%);
  z-index: 0;
}

.lineDotted {
  background: repeating-linear-gradient(
    to bottom,
    var(--color-light-gray),
    var(--color-light-gray) 1px,
    transparent 2px,
    transparent 10px
  );
}

.lineSolid {
  background-color: var(--color-light-gray);
}

.pointContainer {
  z-index: 1;
}
