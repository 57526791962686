.title {
  color: #fff;
}

.dotsContainer {
  border-left: 1px solid red;
}

.dot {
  margin-left: -5px;
}

.affliatedCard {
  background-color: var(--color-dark-gray);
  border-radius: 10px;
  padding: 0.4rem 0.8rem;
}

.status {
  border-left: 1px solid red;
}

.content {
  color: rgba(255, 255, 255, 0.9);
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.line {
  height: 0.33px;
  border: 0.22px solid #212121;
}

.content.show {
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}

.accordion {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.item {
  margin-bottom: -10px;
}

.categoryTitle {
  display: flex;
  justify-content: space-between;
  transition: transform 0.2s ease-in-out;
  align-items: center;
  color: var(--color-light-blue);
}

.content {
  color: var(--color-light-gray);
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.content.show {
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-font-primary);
}

.seeMore {
  border: none;
  color: var(--color-light-gray);
  background-color: transparent;
  margin-left: -5px;
  margin-bottom: 10px;
  margin-top: 7px;
}

.seeMore:focus {
  outline: none;
}

.listContainer {
  list-style-type: none;
  margin-bottom: 6px;
}

.listContainer div {
  display: flex;
  align-items: center;
  gap: 13px;
}

.errorIconContainer {
  text-decoration: line-through;
}

.points {
  color: var(--color-dark-gray);
  text-align: end;
  min-width: 94px;
}

.lineDotted,
.lineSolid {
  width: 1px;
  position: absolute;
  top: 0;
  bottom: 0;
  transform: translateX(-50%);
  z-index: 0;
}

.lineDotted {
  background: repeating-linear-gradient(
    to bottom,
    var(--color-light-gray),
    var(--color-light-gray) 1px,
    transparent 2px,
    transparent 10px
  );
}

.lineSolid {
  background-color: var(--color-light-gray);
}

.pointContainer {
  z-index: 1;
}

.inprogressDot {
  position: absolute;
  left: -7px;
  height: 15px;
  width: 15px;
  color: var(--color-medium-gray);
  background-color: var(--color-medium-gray);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  transition: all 2ms ease-in-out;
}
