.activityItemStatus {
  width: 110px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.5px;
}

.activityItemInfo {
  color: var(--color-lighter-gray);
  padding-right: 22px;
  display: inline-block;
  max-width: 100%;
  position: relative;
  margin-bottom: -4px;
}

.pending {
  color: #000;
  font-weight: bold;
  border: 1px solid #262626;
  border-radius: 5px;
  background-color: #ffe500;
  letter-spacing: -1px;
}

.waiting_deposit {
  color: #000;
  font-weight: bold;
  border: 1px solid #262626;
  border-radius: 5px;
  background-color: #ffe500;
  letter-spacing: -0.5px;
}

.rejected,
.cancelled,
.payment_accredited,
.commission_rejected {
  color: #717171;
  font-weight: bold;
  border: 1px solid #262626;
  border-radius: 5px;
  letter-spacing: -0.5px;
}

.checkin {
  font-weight: bold;
  border: 1px solid #262626;
  border-radius: 5px;
  background-color: #262626;
}

.expired,
.closed,
.no_show_manual,
.no_show {
  color: #525252;
  font-weight: bold;
  border: 1px solid #262626;
  border-radius: 5px;
  letter-spacing: -0.5px;
}

.delayed {
  color: #fff;
  font-weight: bold;
  border: 1px solid #262626;
  border-radius: 5px;
  background-color: #e82e2e;
}

.confirmed {
  color: #717171;
  font-weight: bold;
  border: 1px solid #262626;
  border-radius: 5px;
}

.isCallcenter {
  background-color: #181818;
  padding: 0.75rem 0;
  position: relative;
}

.activityLeftBorder {
  background-color: var(--color-primary);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 20px;
  width: 4px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  overflow: hidden;
}

.statusCircle {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.greenCircle {
  background-color: #00ba00;
}

.redCircle {
  background-color: #ff0000;
}
