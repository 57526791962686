.qr-reader-container section,
.qr-reader-container section video {
  position: relative !important;
}

.qr-reader-container video {
  padding: 1rem;
  background: linear-gradient(black, black) top left, linear-gradient(black, black) top left,
    linear-gradient(black, black) top right, linear-gradient(black, black) top right,
    linear-gradient(black, black) bottom left, linear-gradient(black, black) bottom left,
    linear-gradient(black, black) bottom right, linear-gradient(black, black) bottom right;
  background-size: 1px 35px, 45px 1px;
  background-repeat: no-repeat;
}

.qr-reader-container section > div {
  width: unset !important;
  padding-top: unset !important;
  overflow: hidden !important;
  position: relative !important;
}

.qr-footer {
  height: 45px;
  line-height: 45px;
  text-align: center;
  color: #000;
}

.qr-footer svg {
  margin-top: -2px;
}
