.containerTreatment {
  z-index: 5;
  left: 23%;
  top: 6%;
}

.customerNameContainer {
  background-color: #141414;
  color: #fff;
  border-radius: 7px;
  border: none;
}

.containerPhoneCountry {
  color: grey;
  z-index: 1;
  left: 10px;
  font-size: 0.75rem;
  top: 5%;
  position: absolute;
  pointer-events: none;
}

.containerPhoneNumber {
  position: absolute;
  pointer-events: none;
  color: grey;
  z-index: 1;
  left: 26%;
  font-size: 0.75rem;
  top: 5%;
}

.chevronIcon {
  position: absolute;
  top: 50%;
  left: 45px;
  transform: translateY(-50%);
}

.phoneError {
  color: #fff;
  margin-left: 24%;
  width: 75%;
  position: relative;
}

.phoneInputContainer {
  position: relative;
}

.phoneInputContainer input {
  border: none !important;
}

.custom-label {
  background-color: #f80005;
  margin-top: 8px;
  font-size: 12px;
  padding: 2px 5px 2px 8px;
  position: absolute;
  width: 100%;
}

.container {
  position: relative;
  border: 1px solid var(--color-primary);
  border-radius: 10px;
  padding: 0.5rem;
}

.fieldName {
  position: absolute;
  top: -13px;
  left: 15px;
  background-color: #000;
  padding: 0 0.3rem;
}

.fieldNamePhone {
  position: absolute;
  top: -28px;
  left: 15px;
  background-color: #000;
  padding: 0 0.3rem;
}

.errorLabel {
  background-color: #f80005;
  font-size: 12px;
  margin-top: 8px;
  padding: 2px 5px 2px 8px;
  position: absolute;
  width: 70%;
  margin-right: 2rem;
  z-index: 999;
}
