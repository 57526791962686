.field input,
.field select {
  display: block;
  color: #fff;
  width: 100%;
  padding: 0.4rem 0;
  border: none;
  border-bottom: 1px solid var(--color-primary);
  border-radius: 0;
  outline: 0;
  background-color: #000;
  font-size: 1.1rem;
  font-weight: bold;
}

.errorLabel {
  background-color: #f80005;
  font-size: 12px;
  margin-top: 8px;
  padding: 2px 5px 2px 8px;
  position: absolute;
  width: 92%;
  margin-right: 2rem;
  z-index: 999;
}

.errorLabel:after {
  border: 5px solid transparent;
  border-bottom-color: #f80005;
  bottom: 100%;
  content: '';
  left: 15px;
  margin-left: -5px;
  position: absolute;
}

.reveal {
  position: absolute;
  color: rgb(49, 0, 128);
  right: 40px;
  top: 34%;
  text-indent: 20px;
  transform: translate(50%, -50%);
}

.floatingPlaceholder {
  position: absolute;
  pointer-events: none;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.2s ease all;
}

.field input:focus ~ .floatingPlaceholder,
.field input:not(:focus) .floatingPlaceholder,
.field input:not([value='']) ~ .floatingPlaceholder,
.field .PhoneInput--focus ~ .floatingPlaceholder,
.field.focus .floatingPlaceholder {
  top: -10px;
  font-size: 1rem;
  opacity: 1;
}
