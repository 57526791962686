.link-email .copy {
  right: 5px;
  width: 45px;
  height: 45px;
  padding: 0;
  border-radius: 8px;
  position: absolute;
  z-index: 9;
  top: 8px;
  border: 0;
  line-height: 45px;
}

.link-email hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid var(--color-medium-gray);
}

.link-email .send-email .btn {
  background-color: var(--color-primary) !important;
  width: 100%;
  line-height: 60px;
}

.link-email .send-email .btn .expired-date {
  color: #000;
}

.link-email .send-email .btn .expired-date i {
  margin-right: 10px;
}

.link-email .link-icon {
  position: absolute;
  z-index: 9;
  left: 10px;
  margin: 18px auto;
}
