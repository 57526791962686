.errorContainer {
    display: flex;
    justify-content: center;
    margin-top: 15%;
    height: 100vh;
    margin-left: 30px;
    margin-right: 30px;
  }
  
  .spinnerApp {
    margin: auto;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    padding-bottom: 100px;
    position: fixed;
    width: 100%;
    overflow: hidden;
  }

  .logo {
    padding-bottom: 40px;
    padding-top: 150px;
  }


  .instructionsTitleCheck {
    font-family: 'Inter', sans-serif;
    font-size: 32px;
    font-weight: 800;
    line-height: 29.05px;
    letter-spacing: -0.5px;
    text-align: left;
    margin-bottom: 20px;
    margin-top: 20px;
    color: #d9d9d9;
    width: 80%;
  }
  
  .instructionsSubTitleCheck {
    font-size: 18px;
    text-align: left;
    margin-bottom: 80px;
    width: 80%;
    color: #7e7e7e;
  }