.container {
  width: 100%;
  height: calc(100vh);
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.logoType {
  width: 100%;
  margin-top: 130px;
}

.imgBkg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
