.order-details .card {
  border-radius: 20px;
}

.order-details .show-name {
  font-size: 1.5rem;
  font-weight: 700;
  color: #bbb;
}

.buttonCancel {
  border: 1px solid var(--color-red);
  border-radius: 50%;
  padding: 0.7rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.buttonModify {
  border: 1px solid var(--color-primary);
  border-radius: 50%;
  padding: 0.7rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.cantModify {
  border: 1px solid var(--color-light-gray);
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}

.referenceCode {
  font-size: 0.77rem;
}

.border {
  border-bottom: 1px solid rgb(33, 33, 33);
  padding: 0.8rem 0;
}

.border:first-child {
  padding-top: 3px;
}

.border:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.consumption {
  width: 100%;
  padding: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-primary);
  border-radius: 10px;
  background-color: #000;
  font-size: 0.9rem;
}
