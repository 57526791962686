.show-info {
  border-radius: 0.25rem;
  padding: 1rem;
}

.show-info .edit-box.fixed {
  background-color: transparent !important;
  color: #fff !important;
  border: 1px solid var(--color-medium-gray) !important;
}
