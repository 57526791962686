.iconContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  background-color: #f4f4f4;
  border-radius: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.iconDescription {
  text-align: center;
  margin-top: 8px;
}

.centeredDescription {
  display: flex;
  justify-content: center;
  width: 100%;
}
.img {
  width: 70%;
}
.title {
  font-weight: 700;
  font-size: 24px;
}

.button {
  border-radius: 10px;
}

.target {

  padding-bottom: 20px;
  background-color: #1a1a1a;
  border-radius: 32px 32px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  color: #d9d9d9;
  font-size: 32px;
  font-weight: 800;
}
.line {
  height: 0.1px;
  background-color: rgba(255, 255, 255, 0.5);
  width: 80%;
}

.customLine {
  width: 48px;
  height: 5px;
  background-color: #f2f2f2;
  border-radius: 100px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.card {
  width: 300px;
  height: 180px;
  background: linear-gradient(to right, #3f3f3f, #1c1c1c);
  color: #fff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Arial', sans-serif;
  perspective: 1000px;
}

.cardInner {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.5s;
}

.card:hover .cardInner {
  transform: rotateY(180deg);
}

.cardFront,
.cardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.cardFront {
  background: #2c3e50;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}

.cardBack {
  background: #2c3e50;
  transform: rotateY(180deg);
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #fff;
}

.cardNumber {
  font-size: 24px;
  letter-spacing: 2px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cardNumber span {
  font-size: 18px;
}

.dot {
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 50%;
  margin-right: 8px;
}

.expirationDate {
  font-size: 14px;
  margin-bottom: 10px;
}

.issuer {
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  bottom: 20px;
  right: 20px;
}


.fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000; 
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-bottom: 100px;
  position: fixed;
  width: 100%;
  overflow: hidden;
}

.logo {
  padding-bottom: 40px;
  padding-top: 150px;
}

.instructionsTitleCheck {
  font-family: 'Inter', sans-serif;
  font-size: 32px;
  font-weight: 800;
  line-height: 29.05px;
  letter-spacing: -0.5px;
  text-align: left;
  margin-bottom: 20px;
  margin-top: 20px;
  color: #d9d9d9;
  width: 80%;
}

.instructionsSubTitleCheck {
  font-size: 18px;
  text-align: left;
  margin-bottom: 80px;
  width: 80%;
  color: #7e7e7e;
}
