.container {
  border-radius: 7px;
  background-color: #191919;
  color: #fff;
}

.border {
  height: 0.22px;
  width: 95%;
  background-color: #cacaca;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
