.ticket {
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  color: #000;
  display: flex;
  min-height: 89px;
  overflow: hidden;
  padding: 0.5rem 0.75rem;
}

.ticket.discount {
  padding: 0.5rem 0.75rem 0.25rem;
}

.ticket .type {
  color: #000;
}

.ticket .price {
  color: #000;
  font-size: 1rem;
  margin: 0;
}

.ticket h3 > div {
  display: inline-block;
}

.ticket h3 .price-discount,
.price-discount .integer {
  color: var(--color-medium-gray);
  text-decoration: line-through;
  font-weight: 400;
}
.summary-price-discount .integer {
  color: #fff;
  font-weight: 400;
  text-decoration: line-through;
}
.summary-commission-price-discount {
  color: #fff;
  font-weight: 400;
  text-decoration: line-through;
}

.price-commission {
  background-color: var(--color-green);
  border-radius: 6px;
  color: #000;
  padding: 0 4px;
}

.item-commission {
  height: 14px;
  padding: 2px;
}

.ticket .ticket-container .price-discount .money {
  color: var(--color-light-gray);
}

.ticket .description-discount,
.purchase-details .description-discount {
  background-color: var(--color-dark-green);
  color: #fff;
  padding: 0 10px;
  border-radius: 6px;
  text-transform: uppercase;
  display: inline-block;
  font-size: 0.7rem;
  line-height: 1.1rem;
}

.ticket .wrapper:hover .more-info,
.ticket.sold-out-product:hover .more-info {
  opacity: 0.8;
  visibility: visible;
}

/* .ticket .more-info {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: -15px;
  opacity: 0;
  background-color: var(--color-primary);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  transition: opacity 0.3s ease-in-out;
  visibility: hidden;
} */

.ticket-modality h3 {
  margin: 0;
}

.ticket-image {
  width: 60px;
  overflow: hidden;
  padding-right: 0;
  position: relative;
  height: 100%;
}

.ticket-image img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  object-fit: cover;
  max-height: 75px;
  max-width: 45px;
}

.tickets-counter {
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  color: #000;
}

.ticket-buttons {
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
}

.ticket-buttons button {
  border: none;
  color: #000;
  outline: 0;
  padding: 0;
}

.ticket-buttons button.add,
.ticket-buttons button.remove {
  width: 45px;
  height: 45px;
  line-height: 43px;
  border-radius: 50%;
  outline: 0;
  font-size: 3rem;
  border: 1px solid transparent;
}

.ticket-buttons button.add {
  background-color: var(--color-primary);
  color: #fff;
}

.ticket-buttons button.remove {
  color: #000;
  opacity: 0;
  background-color: transparent;
  border: 1px solid var(--color-medium-gray);
}

.ticket-buttons button.remove:not([disabled]) {
  opacity: 1;
}

.ticket.sold-out-product .col-auto.pl-0 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ticket-soldout {
  background-color: var(--color-light-gray);
  color: #fff;
  font-size: 11px;
  height: 1.2rem;
  width: 5rem;
  text-transform: uppercase;
  border-radius: 7px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ticket-high-demand {
  background-color: var(--color-red);
  color: #fff;
  padding: 1px 5px;
  border-radius: 0.25rem;
  text-transform: uppercase;
  display: inline-block;
  margin-top: 0.1rem;
  line-height: 13px;
}

.price-commission-cart-button {
  font-size: 0.9rem;
  border-radius: 5px;
  background-color: var(--color-green);
  color: #000;
  font-weight: lighter;
  padding: 0.1rem 0.3rem;
  display: flex;
  justify-content: space-between;
}

.price-total-cart-button {
  font-size: 16px;
  font-weight: 500;
}

.price-total-cart-button {
  font-size: 1.1rem;
}

.price-total-cart-button .moneyCents {
  top: 1px;
}