.pointsContainer {
  padding: 1rem;
}

.myPoints {
  color: #000;
}

.activity {
  padding: 0.7rem;
}
