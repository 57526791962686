.img {
  width: 70%;
}

.container {
  color: var(--color-font-primary);
  padding-top: calc(11px + env(safe-area-inset-top));
}

.text {
  margin-top: 20%;
}

.btn {
  background-color: var(--color-primary);
  color: #000 !important;
}

.buttonContinue {
  width: 100%;
  height: 4rem;
  font-size: 1.1rem;
  left: 0;
  bottom: 7rem;
}

.buttonNext {
  width: 100%;
  height: 4rem;
  font-size: 1.1rem;
  left: 0;
  bottom: 3rem;
}

.btnOmit {
  background-color: #000 !important;
  border: 2px solid var(--color-primary);
}

.dots {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
}

.minHeigth {
  min-height: 315px;
}

.omit {
  padding-top: calc(11px + env(safe-area-inset-top));
}
