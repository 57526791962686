.userImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.imgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 81px;
  height: 50px;
}
